import { FC } from 'react'
import * as S from './PositionsCards.styled'
import { Container } from '@atoms/Container'
import { IComponentModulesPositionsCards } from '@generated/index'

type PositionsCardsProps = IComponentModulesPositionsCards

export const PositionsCards: FC<PositionsCardsProps> = ({ title, positions }) => {
  return (
    <Container variant={'white'}>
      <S.Wrap>
        <S.Title>{title}</S.Title>
        <S.Grid>{positions && positions.map((position, index) => <S.Item key={index} {...position} />)}</S.Grid>
      </S.Wrap>
    </Container>
  )
}
