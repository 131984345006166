import styled from '@emotion/styled'
import { H2 } from '@atoms/Title'
import { BenefitIcon } from '@molecules/BenefitIcon'
import { Icon } from '@molecules/BenefitIcon/BenefitIcon.styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  padding-top: 60px;
  padding-bottom: 40px;

  ${mediaQuery.tablet.up} {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  ${mediaQuery.desktop.up} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`

export const Title = styled(H2)`
  display: block;
  text-align: center;
  margin-bottom: 40px;

  ${mediaQuery.tablet.up} {
    margin-bottom: 80px;
  }

  ${mediaQuery.desktop.up} {
    margin-bottom: 100px;
    text-align: left;
  }
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px 16px;

  ${mediaQuery.tablet.up} {
    gap: 60px 16px;
  }

  ${mediaQuery.desktop.up} {
    gap: 100px 16px;
  }
`

export const Item = styled(BenefitIcon)`
  width: calc((100% / 2) - 16px);

  ${Icon} {
    height: 60px;
  }

  ${mediaQuery.tabletXs.up} {
    ${Icon} {
      height: 100px;
    }
  }

  ${mediaQuery.tablet.up} {
    width: calc((100% / 4) - 16px);

    ${Icon} {
      height: 80px;
    }
  }

  ${mediaQuery.desktop.up} {
    ${Icon} {
      height: 100px;
    }
  }
`
