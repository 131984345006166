import { Container } from '@atoms/Container'
import { FormattedText } from '@atoms/FormattedText'
import { IComponentModulesTextArea } from '@generated/index'
import { FC } from 'react'
import * as S from './TextArea.styled'

type TextAreaProps = IComponentModulesTextArea

export const TextArea: FC<TextAreaProps> = ({ title, text }) => {
  return (
    <Container>
      <S.Wrap>
        {title && <S.Title>{title}</S.Title>}
        {text && <FormattedText dangerouslySetInnerHTML={{ __html: text }} />}
      </S.Wrap>
    </Container>
  )
}
