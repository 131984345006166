export const getStrapiRoot = (): string => {
  return process.env.NEXT_PUBLIC_STRAPI_ROOT as string
}

export const getCsUrl = (): string => {
  return process.env.NEXT_PUBLIC_CS_URL as string
}

export const getEnUrl = (): string => {
  return process.env.NEXT_PUBLIC_EN_URL as string
}

export const getBaseUrl = (): string => {
  return process.env.NEXT_PUBLIC_BASE_URL as string
}

export const isProduction = (): boolean => {
  return (process.env.NEXT_PUBLIC_ENV as string) === 'production'
}
