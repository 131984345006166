import styled from '@emotion/styled'
import { H3 } from '@atoms/Title'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  text-align: center;
`

export const Title = styled(H3)``

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  ${mediaQuery.tablet.up} {
    margin-bottom: 40px;
  }

  ${mediaQuery.desktop.up} {
    margin-bottom: 52px;
  }
`
