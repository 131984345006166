import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
`

export const SvgWrap = styled.div`
  margin-bottom: 48px;
  aspect-ratio: 190 / 164;
  max-width: 140px;
  width: 100%;
  filter: drop-shadow(-4px -4px 3px #fff) drop-shadow(-4px -4px 14px rgba(0, 0, 0, 0.1))
    drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.12));

  svg {
    width: 100%;
    color: ${({ theme }) => theme.colors.primary};
  }

  ${mediaQuery.tabletXs.up} {
    filter: drop-shadow(10px 10px 20px #dc6156);
    max-width: 190px;
    svg {
      color: #fff;
    }
  }
`
