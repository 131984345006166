import styled from '@emotion/styled'
import { H2 } from '@atoms/Title'
import { PositionCard as PositionCardComponent } from '@molecules/PositionCard'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;

  ${mediaQuery.tablet.up} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`

export const Title = styled(H2)`
  margin-bottom: 32px;
  text-align: center;

  ${mediaQuery.tablet.up} {
    text-align: left;
    margin-bottom: 70px;
  }
`

export const Grid = styled.div`
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  ${mediaQuery.tabletXs.up} {
    grid-template-columns: 1fr 1fr;
  }

  ${mediaQuery.tablet.up} {
    gap: 50px;
  }

  ${mediaQuery.desktop.up} {
    gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const Item = styled(PositionCardComponent)`
  flex: 0 1 390px;
`
