import styled from '@emotion/styled'
import { H3 } from '@atoms/Title'
// @ts-ignore
import Arrow from '../../atoms/Icons/ArrowLong.svg'
import { mediaQuery } from '../../../themes/utils/breakpoints'
import Link from 'next/link'

export const SvgWrap = styled.div`
  width: 114px;
  height: 95px;
  margin-bottom: 32px;
  color: ${(props) => props.theme.colors.primary};

  svg {
    fill: none;
    width: 100%;
  }

  .moveMe {
    transition: transform 0.3s ease-in-out;
  }
`

export const Title = styled(H3)`
  margin-bottom: 8px;

  ${mediaQuery.tabletXs.up} {
    min-height: 48px;
  }
`

export const Text = styled.div`
  margin-bottom: 32px;

  ${mediaQuery.tabletXs.up} {
    min-height: 72px;
  }
`

export const Icon = styled.div`
  color: ${(props) => props.theme.colors.primary};
  will-change: margin-left;
  transition: margin-left 0.3s ease-in-out;
`

export const ArrowIcon = styled(Arrow)`
  width: 120px;
`

export const Item = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'disableLink',
})<{ disableLink: boolean }>`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 54px 30px 32px;
  position: relative;
  width: 100%;
  pointer-events: ${(props) => (props.disableLink ? 'none' : 'auto')};
  color: ${(props) => props.theme.colors.body};

  &:hover {
    &::before {
      opacity: 1;
    }

    ${Icon} {
      margin-left: 10px;
    }

    ${SvgWrap} {
      .moveMe {
        transform: translateY(-2px);
      }
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 2px 2px 4px #fff, inset -4px -4px 4px ${(props) => props.theme.colors.grayDarker},
      inset 10px 10px 14px rgba(0, 0, 0, 0.08), inset -10px -10px 14px #ffffff;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
  }

  &:first-of-type {
    &::before {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  &:last-of-type {
    &::before {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &:not(:first-of-type) {
    border-top: 1px solid #d9d9d9;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #fff;
  }

  ${mediaQuery.tabletXs.up} {
    padding: 54px 60px 56px;
    width: 50%;

    &:first-of-type {
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;

      &::before {
        border-radius: 20px 0 0 0;
      }
    }

    &:nth-of-type(2) {
      border-top: 0;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #d9d9d9;

      &::before {
        border-top-right-radius: 20px;
      }
    }

    &:nth-of-type(3) {
      border-right: 1px solid #d9d9d9;
      border-top: 1px solid #fff;

      &::before {
        border-radius: 0 0 0 20px;
      }
    }

    &:nth-of-type(4) {
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;

      &::before {
        border-radius: 0 0 20px 0;
      }
    }
  }

  ${mediaQuery.desktop.up} {
    width: calc(100% / 4);
    border: 0;

    &:nth-of-type(2),
    &:nth-of-type(3) {
      &::before {
        border-radius: 0;
      }
    }

    &:first-of-type {
      &::before {
        border-radius: 20px 0 0 20px;
      }
    }

    &:last-of-type {
      &::before {
        border-radius: 0 20px 20px 0;
      }
    }

    &:not(:last-of-type) {
      border-top: 0;
      border-left: 0;
      border-bottom: 0;
      border-right: 1px solid #d9d9d9;
    }

    &:not(:first-of-type) {
      border-top: 0;
      border-left: 0;
      border-bottom: 0;
      border-left: 1px solid #fff;
    }
  }
`
