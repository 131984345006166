import styled from '@emotion/styled'
import { H2 } from '@atoms/Title'
import { mediaQuery } from '../../../themes/utils/breakpoints'
import { Container as ContainerComponent } from '../../atoms/Container'

export const Container = styled(ContainerComponent)`
  margin-bottom: 40px;

  ${mediaQuery.tablet.up} {
    margin-bottom: 120px;
  }
`

export const Title = styled(H2)`
  margin-bottom: 40px;
  width: 100%;
  text-align: center;

  ${mediaQuery.tablet.up} {
    margin-bottom: 62px;
  }

  ${mediaQuery.desktop.up} {
    text-align: left;
  }
`

export const LogosWrap = styled.div`
  overflow: hidden;
  margin-left: -10px;
  margin-right: -10px;

  ${mediaQuery.desktop.up} {
    margin-left: 0;
    margin-right: 0;
  }
`

export const LogosContainer = styled.div`
  display: flex;

  ${mediaQuery.desktop.up} {
    justify-content: space-between;
  }
`

export const LogoItem = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  margin-left: 32px;
  margin-right: 32px;

  ${mediaQuery.tabletXs.up} {
    margin-left: 60px;
    margin-right: 60px;
  }

  ${mediaQuery.tablet.up} {
    margin-left: 100px;
    margin-right: 100px;
  }

  ${mediaQuery.desktop.up} {
    margin-right: 0;
    margin-left: 0;
  }
`

export const LogoImg = styled.div`
  filter: grayscale(100%) opacity(0.4);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: grayscale(0%) opacity(1);
    transition: 0.3s ease-in-out;
  }

  svg {
    height: 41px;
    width: auto;
  }
`
