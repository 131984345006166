import { H3 } from '@atoms/Title'
import styled from '@emotion/styled'
import { getStrapiRoot } from '@utils/env'
import { mediaQuery } from 'themes/utils/breakpoints'

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${mediaQuery.tablet.up} {
    flex-direction: row;
  }
`

export const DevicesWrap = styled.div`
  display: flex;
  align-items: flex-end;
  width: ${({ theme }) => theme.refSlider.deviceColumnWidth.mobile};

  ${mediaQuery.tablet.up} {
    width: ${({ theme }) => theme.refSlider.deviceColumnWidth.tablet};
  }

  ${mediaQuery.desktop.up} {
    width: ${({ theme }) => theme.refSlider.deviceColumnWidth.desktop};
  }
`

export const DesktopFrame = styled.div`
  aspect-ratio: 16/9;
  background-color: ${({ theme }) => theme.colors.grayDarker};
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 80px;
  box-shadow: 20px 20px 40px #dcdcdc, -20px -20px 40px #ffffff;
  width: 100%;
  display: none;

  ${mediaQuery.tablet.up} {
    width: 80%;
    display: block;
  }
`

export const Desktop = styled.div<{ bgUrl?: string }>`
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;
  background-color: #fff;
  display: block;
  overflow-y: auto;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: block;
    background-image: ${(props) => (props.bgUrl ? `url(${getStrapiRoot() + props.bgUrl})` : '')};
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
    filter: grayscale(100%);
    opacity: 0.1;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7e7e7e;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
`

export const EmptyImage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;

  svg {
    width: 100%;
  }
`

export const MobileFrame = styled.div`
  aspect-ratio: 7/10;
  background-color: ${({ theme }) => theme.colors.grayDarker};
  padding-top: 24px;
  padding-bottom: 30px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20px;
  position: relative;
  box-shadow: -20px -20px 40px rgba(255, 255, 255, 0.5), 20px 20px 40px #dcdcdc;
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;

  ${mediaQuery.tablet.up} {
    width: 20%;
    left: -80px;
    margin-bottom: 0;
  }
`

export const Mobile = styled.div`
  position: relative;
  aspect-ratio: 7/10;
  width: 100%;
  background-color: #fff;
  display: block;
  overflow-y: auto;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 1px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7e7e7e;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
`

export const CompanyWrap = styled.div`
  width: ${({ theme }) => theme.refSlider.textColumnWidth.mobile};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery.tablet.up} {
    width: ${({ theme }) => theme.refSlider.textColumnWidth.tablet};
    align-items: flex-start;
  }

  ${mediaQuery.desktop.up} {
    width: ${({ theme }) => theme.refSlider.textColumnWidth.desktop};
  }
`

export const LogoWrap = styled.div`
  filter: grayscale(100%) opacity(0.4);
  margin-bottom: 24px;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;

  svg {
    display: block;
    max-height: 100%;
    width: auto;
    min-width: 0;
  }

  ${mediaQuery.tablet.up} {
    height: 50px;
    width: auto;
    justify-content: flex-start;
  }

  ${mediaQuery.desktop.up} {
    margin-bottom: 48px;
  }
`

export const Title = styled(H3)`
  margin-bottom: 12px;

  ${mediaQuery.desktop.up} {
    margin-bottom: 24px;
  }
`

export const CompanyText = styled.div`
  text-align: center;
  max-width: 450px;

  ${mediaQuery.tablet.up} {
    text-align: left;
  }
`

export const TagsWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
  flex-wrap: wrap;

  ${mediaQuery.desktop.up} {
    margin-bottom: 54px;
  }
`

export const Tag = styled.div`
  margin-right: 8px;
`
