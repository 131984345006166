import { FC } from 'react'
import * as S from './ServiceCardsItem.styled'
import { IComponentComponentsServiceCard } from '@generated/index'

export type ServiceCardProps = IComponentComponentsServiceCard

export const ServiceCardsItem: FC<ServiceCardProps> = ({ title, text, svg, link }) => {
  let href = ''
  if (link?.url) {
    href = link.url
  }
  if (link?.page?.slug) {
    href = link.page.slug
  }
  const svgCode = svg?.code ?? ''
  return (
    <S.Item href={href} disableLink={!href.length}>
      <S.SvgWrap
        dangerouslySetInnerHTML={{
          __html: svgCode,
        }}
      ></S.SvgWrap>
      <S.Title>{title}</S.Title>
      <S.Text>
        <p>{text}</p>
      </S.Text>
      {!!href.length && (
        <S.Icon>
          <S.ArrowIcon />
        </S.Icon>
      )}
    </S.Item>
  )
}
