import { FC } from 'react'
import * as S from './CtaIconBox.styled'
import { ButtonIcon } from '../ButtonIcon'
import { IComponentComponentsButton } from '@generated/index'

export type CtaIconBoxProps = {
  svgCode: string
  button?: IComponentComponentsButton
}

export const CtaIconBox: FC<CtaIconBoxProps> = ({ svgCode, button }) => {
  return (
    <S.Wrap>
      <S.SvgWrap
        dangerouslySetInnerHTML={{
          __html: svgCode,
        }}
      ></S.SvgWrap>
      <ButtonIcon variant={'hero'} moduleBackground={'primary'} button={button}>
        {button?.title ?? ''}
      </ButtonIcon>
    </S.Wrap>
  )
}
