import { FC } from 'react'
import { H1Big } from '@atoms/Title'

export type HeroTitleProps = {
  children: React.ReactNode
}

export const HeroTitle: FC<HeroTitleProps> = ({ children }) => {
  return <H1Big>{children}</H1Big>
}
