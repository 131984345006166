import styled from '@emotion/styled'
import { H2, H4 } from '@atoms/Title'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-template-rows: 40px auto;
  grid-template-areas: 'icon title' 'text text';
  grid-column-gap: 24px;
  grid-row-gap: 16px;

  ${mediaQuery.tabletXs.up} {
    grid-template-areas: 'icon title' 'icon text';
    grid-row-gap: 32px;
  }
`

export const Icon = styled.div`
  grid-area: icon;
  max-height: 100%;
  align-self: start;

  svg {
    max-height: 100%;
  }
`

export const Title = styled(H2)`
  grid-area: title;
  align-self: center;
`

export const TextWrap = styled.div`
  grid-area: text;
  h2 {
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.colors.secondary};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  p {
    line-height: 1.5;
    margin-bottom: 8px;
  }
  strong {
    color: ${(props) => props.theme.colors.secondary};
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
  }
`

export const List = styled.ul`
  margin-top: 20px;
`

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  position: relative;
  margin-bottom: 16px;

  h4 {
    font-weight: 600;
    margin-bottom: 4px;
  }

  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    left: 2px;
    top: 6px;
  }
`

export const ListItemTitle = styled(H4)``

export const ListItemText = styled.p``
