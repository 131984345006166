import styled from '@emotion/styled'
import { H2 } from '@atoms/Title'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Content = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;

  ${mediaQuery.tabletXs.up} {
    padding-top: 112px;
    padding-bottom: 160px;
  }
`

export const Title = styled(H2)`
  margin-bottom: 48px;
  text-align: center;
  width: 100%;

  ${mediaQuery.tablet.up} {
    text-align: left;
  }
`;

export const Intro = styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
  text-align: center;

  ${mediaQuery.tablet.up} {
    text-align: left;
    justify-content: space-around;
  }
  
  ${mediaQuery.desktop.up} {
    justify-content: space-between;
  }
`;

export const TextWrap = styled.div`
  max-width: 600px;
  width: 100%;

  ${mediaQuery.tablet.up} {
  }
`;

export const ButtonWrap = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: center;

  ${mediaQuery.tablet.up} {
    width: auto;
    margin-top: 0;
    justify-content: flex-start;
  }
`;
