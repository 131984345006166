import { FC, useCallback, useEffect, useRef } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import * as S from './DynamicImageSlider.styled'
import Image from 'next/image'
import { getStrapiRoot } from '@utils/env'
import { StrapiImageEntity } from '@strapi/strapiImage'
import { sizes } from '../../../themes/utils/breakpoints'

type DynamicImageSliderProps = {
  items: {
    title?: string
    text?: string
    media: StrapiImageEntity
  }[]
}

export const DynamicImageSlider: FC<DynamicImageSliderProps> = ({ items }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    dragFree: false,
  })
  let sliderItems = items

  if (items.length <= 4) {
    sliderItems = [...items, ...items]
  }

  const rafId = useRef(0)

  const animate = useCallback(() => {
    if (!embla || !rafId.current) return

    const engine = embla.internalEngine()
    engine.location.add(-1)
    engine.target.set(engine.location)
    engine.scrollLooper.loop(-1)
    engine.slideLooper.loop()
    engine.translate.to(engine.location)
    rafId.current = requestAnimationFrame(animate)
  }, [embla])

  const startAutoScroll = useCallback(() => {
    rafId.current = requestAnimationFrame(animate)
  }, [animate])

  const stopAutoScroll = useCallback(() => {
    const frame = rafId.current || 0
    // @ts-ignore
    rafId.current = cancelAnimationFrame(frame)
  }, [])

  useEffect(() => {
    if (!embla) return

    startAutoScroll()
    return () => stopAutoScroll()
  }, [embla, startAutoScroll, stopAutoScroll])

  return (
    <S.Viewport ref={viewportRef} onPointerEnter={stopAutoScroll} onPointerLeave={startAutoScroll}>
      <S.Wrap>
        {sliderItems.map((item, index) => (
          <S.Item key={index}>
            {item.media.placeholder && (
              <Image
                src={getStrapiRoot() + item.media.url}
                alt={item.media.alternativeText ?? ''}
                fill={true}
                object-position="center"
                style={{ objectFit: 'cover', objectPosition: 'center' }}
                placeholder={'blur'}
                blurDataURL={item.media.placeholder}
                sizes={`${sizes.desktop} 34vw`}
              />
            )}
            {!item.media.placeholder && (
              <Image
                src={getStrapiRoot() + item.media.url}
                alt={item.media.alternativeText ?? ''}
                fill={true}
                object-position="center"
                style={{ objectFit: 'cover', objectPosition: 'center' }}
                sizes={`${sizes.desktop} 34vw`}
              />
            )}
          </S.Item>
        ))}
      </S.Wrap>
    </S.Viewport>
  )
}
