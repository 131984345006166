import { H1 } from '@atoms/Title'
import styled from '@emotion/styled'
import { mediaQuery } from 'themes/utils/breakpoints'

export const Wrap = styled.div`
  padding-top: 2rem;

  ${mediaQuery.tablet.up} {
    padding-top: 0rem;
  }
`

export const Title = styled(H1)`
  margin-bottom: 1rem;
  font-size: 3rem;

  ${mediaQuery.tablet.up} {
    margin-bottom: 2rem;
    font-size: 4rem;
  }
`
