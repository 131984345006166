import styled from '@emotion/styled'
import ArrowLong from '@atoms/Icons/ArrowLong.svg'
import breakpoints, { mediaQuery } from 'themes/utils/breakpoints'

const buttonDefault = `
  background-color: transparent;
  border: none;

  &[disabled] {
    opacity: 0.5;
  }
`

export const Slider = styled.div`
  overflow: hidden;
  padding-bottom: 60px;
  margin-bottom: -60px;
`

export const SliderSlideInner = styled.div<{ scale: number }>`
  transform: scale(${(props) => props.scale});
  max-width: ${({ theme }) => theme.container.maxWidth};
  padding: ${({ theme }) => theme.container.padding};
  margin: 0 auto;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    transform: scale(1) !important;
  }
`

export const SliderViewport = styled.div``

export const SliderContainer = styled.div`
  display: flex;
`

export const NavContainerMobile = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-left: ${({ theme }) => theme.container.padding};
  padding-right: ${({ theme }) => theme.container.padding};
  order: 1;
  margin-bottom: 16px;
  margin-top: 16px;

  ${mediaQuery.tablet.up} {
    display: none;
  }
`

export const NavContainer = styled.div`
  max-width: ${({ theme }) => theme.container.maxWidth};
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-left: ${({ theme }) => theme.container.padding};
  padding-right: ${({ theme }) => theme.container.padding};
  order: 1;
  margin-top: 16px;

  ${mediaQuery.tablet.up} {
    position: relative;
    z-index: 2;
    margin-top: -10px;
  }
`

export const Nav = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ theme }) => theme.refSlider.textColumnWidth.mobile};

  ${mediaQuery.tablet.up} {
    width: ${({ theme }) => theme.refSlider.textColumnWidth.tablet};
    justify-content: flex-start;
  }

  ${mediaQuery.desktop.up} {
    width: ${({ theme }) => theme.refSlider.textColumnWidth.desktop};
  }
`

export const ButtonIcon = styled(ArrowLong)`
  padding: 0 10px;
  width: 100px;
  display: block;
  color: ${({ theme }) => theme.colors.primary};

  ${mediaQuery.tablet.up} {
    width: 120px;
  }
`

export const PrevButton = styled.button`
  ${buttonDefault}

  ${ButtonIcon} {
    transform: rotate(180deg);
  }
`

export const NextButton = styled.button`
  ${buttonDefault}
`

export const SliderSlide = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
`
