import { FC } from 'react'
import { Container } from '@atoms/Container'
import * as S from './TwoColumnsText.styled'
import { IComponentModulesTwoColumnsText } from '@generated/index'

export type TwoColumnsTextProps = IComponentModulesTwoColumnsText

export const TwoColumnsText: FC<TwoColumnsTextProps> = ({ columns }) => {
  return (
    <Container>
      <S.Wrap>{columns && columns.map((column, index) => <S.Column key={index} {...column} />)}</S.Wrap>
    </Container>
  )
}
