import styled from '@emotion/styled'
import { mediaQuery } from 'themes/utils/breakpoints'

export const Wrap = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  ${mediaQuery.tablet.up} {
    flex-direction: row;
    margin-top: 140px;
    justify-content: space-around;
  }
`