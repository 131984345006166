import { FC, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { IComponentComponentsNumber } from '@generated/index'
import NumberScroller from 'number-scroller'
import * as S from './FloatingNumber.styled'

export type FloatingNumberProps = IComponentComponentsNumber

export const FloatingNumber: FC<FloatingNumberProps> = ({ numberValue, prefix, subfix, text }) => {
  const { ref, inView } = useInView()
  const [firstView, setFirstView] = useState(false)
  const [number, setNumber] = useState(0)
  const frequency = (1000 / numberValue) * 1.1

  if (inView && !firstView) {
    setNumber(numberValue)
    setFirstView(true)
  }

  return (
    <S.Wrap>
      <S.Number ref={ref}>
        {prefix}
        <NumberScroller to={number} renderFrequency={frequency} />
        {subfix}
      </S.Number>
      <S.Text>{text}</S.Text>
    </S.Wrap>
  )
}
