import { FC } from 'react'
import * as S from './FloatingInput.styled'
import { useFormContext } from 'react-hook-form'

export type FloatingInputProps = {
  label: string
  type: string
  name: string
  honey?: boolean
  required?: boolean | string
  className?: string
}

export const FloatingInput: FC<FloatingInputProps> = ({
  label,
  type,
  name,
  className,
  required = false,
  honey = false,
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const value = watch(name)

  return (
    <S.InputGroup className={className} honey={honey}>
      <S.Input
        id={name}
        type={type}
        error={!!errors[name]}
        {...register(name, { required: required })}
        aria-invalid={!!errors[name]}
      />
      <S.Label htmlFor={name} isFilled={!!value} error={!!errors[name]}>
        {label}
      </S.Label>
      {/* @ts-ignore */}
      {errors?.[name] && <S.ErrorMessage>{errors?.[name]?.message ? errors?.[name]?.message : ''}</S.ErrorMessage>}
    </S.InputGroup>
  )
}
