import { FC, useCallback, useEffect, useRef, useState } from 'react'
import * as S from './ReferencesLogos.styled'
import { IComponentModulesReferencesLogos, IReference } from '@generated/index'
import { useWindowWidth } from '@react-hook/window-size'
import useEmblaCarousel from 'embla-carousel-react'
import breakpoints from '../../../themes/utils/breakpoints'

export type ReferenceLogosProps = IComponentModulesReferencesLogos

export const ReferencesLogos: FC<ReferenceLogosProps> = ({ title, references }) => {
  const windowWidth = useWindowWidth()
  const [isDesktop, setIsDesktop] = useState<boolean>(true)
  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    dragFree: false,
  })

  useEffect(() => {
    setIsDesktop(windowWidth > breakpoints.desktop)
  }, [windowWidth])

  const rafId = useRef(0)

  const animate = useCallback(() => {
    if (!embla || !rafId.current) return

    const engine = embla.internalEngine()
    engine.location.add(-1)
    engine.target.set(engine.location)
    engine.scrollLooper.loop(-1)
    engine.slideLooper.loop()
    engine.translate.to(engine.location)
    rafId.current = requestAnimationFrame(animate)
  }, [embla])

  const startAutoScroll = useCallback(() => {
    rafId.current = requestAnimationFrame(animate)
  }, [animate])

  const stopAutoScroll = useCallback(() => {
    const frame = rafId.current || 0
    // @ts-ignore
    rafId.current = cancelAnimationFrame(frame)
  }, [])

  useEffect(() => {
    if (!embla) return

    embla.on('pointerDown', stopAutoScroll)
    embla.on('settle', startAutoScroll)

    startAutoScroll()
    return () => stopAutoScroll()
  }, [embla, startAutoScroll, stopAutoScroll])

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.LogosWrap ref={!isDesktop ? viewportRef : null}>
        <S.LogosContainer>
          {references?.map((reference:IReference, index:number) => {
            return (
              <S.LogoItem key={index}>
                {reference?.logoSvg?.code && (
                  <S.LogoImg
                    dangerouslySetInnerHTML={{
                      __html: reference.logoSvg.code,
                    }}
                  />
                )}
              </S.LogoItem>
            )
          })}
        </S.LogosContainer>
      </S.LogosWrap>
    </S.Container>
  )
}
