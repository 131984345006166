import { FC } from 'react'
import * as S from './TextGrid.styled'
import { IPosition } from '@generated/index'
import { TextGridItem } from '@molecules/TextGrid/TextGridItem'

export type TextGridProps = {
  positions: IPosition[]
}

export const TextGrid: FC<TextGridProps> = ({ positions }) => {
  return (
    <S.Grid>
      {positions.map((position, index) => (
        <TextGridItem key={index} title={position.name} text={position.cardExcerpt ?? ''} link={position.slug} />
      ))}
    </S.Grid>
  )
}
