import { FC } from 'react'
import { IComponentComponentsTextColumn } from '@generated/index'
import * as S from './TextColumn.styled'
import { FormattedText } from '@atoms/FormattedText'

type TextColumnProps = IComponentComponentsTextColumn & { className?: string }

export const TextColumn: FC<TextColumnProps> = ({ title, text, textAfter, icon, listItems, className }) => {
  return (
    <S.Wrap className={className}>
      {icon && <S.Icon dangerouslySetInnerHTML={{ __html: icon }} />}
      {title && <S.Title>{title}</S.Title>}
      <S.TextWrap>
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
        {listItems && (
          <S.List>
            {listItems.map((item, index) => (
              <S.ListItem key={index}>
                {item.title && <S.ListItemTitle>{item.title}</S.ListItemTitle>}
                {item.text && <FormattedText dangerouslySetInnerHTML={{ __html: item.text }} />}
              </S.ListItem>
            ))}
          </S.List>
        )}
        {textAfter && <div dangerouslySetInnerHTML={{ __html: textAfter }} />}
      </S.TextWrap>
    </S.Wrap>
  )
}
