import styled from '@emotion/styled'
import { H3 } from '@atoms/Title'
import ArrowLong from '../../atoms/Icons/ArrowLong.svg'
import { mediaQuery } from '../../../themes/utils/breakpoints'
import Link from 'next/link'

export const ArrowIcon = styled(ArrowLong)`
  width: 120px;
  height: 16px;
  transition: margin-left 0.3s;
  margin-left: 32px;
`

export const Wrap = styled(Link)`
  color: ${(props) => props.theme.colors.body};
  text-decoration: none;
  display: block;
  padding: 32px 40px;
  position: relative;

  &:not(:first-of-type) {
    border-top: 1px solid #fff;
  }

  &:first-of-type {
    &::before {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  &:last-of-type {
    &::before {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #d9d9d9;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 2px 2px 4px #fff, inset -4px -4px 4px ${(props) => props.theme.colors.grayDarker},
      inset 10px 10px 14px rgba(0, 0, 0, 0.08), inset -10px -10px 14px #ffffff;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    ${ArrowIcon} {
      margin-left: 40px;
    }
  }

  ${mediaQuery.tablet.up} {
    width: 50%;
    &:not(:first-child) {
      border: 0;
    }

    &:not(:last-child) {
      border: 0;
    }

    &:first-of-type {
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;

      &::before {
        border-radius: 20px 0 0 0;
      }
    }

    &:nth-of-type(2) {
      border-top: 0;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #d9d9d9;

      &::before {
        border-top-right-radius: 20px;
      }
    }

    &:nth-of-type(3) {
      border-right: 1px solid #d9d9d9;
      border-top: 1px solid #fff;

      &::before {
        border-radius: 0 0 0 20px;
      }
    }

    &:nth-of-type(4) {
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;

      &::before {
        border-radius: 0 0 20px 0;
      }
    }
  }
`

export const Title = styled(H3)`
  margin-bottom: 12px;
`

export const Text = styled.div`
  margin-bottom: 36px;

  ${mediaQuery.tablet.up} {
    margin-bottom: 56px;
  }
`

export const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
`

export const LinkText = styled.div``
