import { FC, useState } from 'react'
import { Container } from '@atoms/Container'
import * as S from './ContactForm.styled'
import { FormProvider, useForm } from 'react-hook-form'
import { ContactFormValues } from '../../../types/contactForm'
import { ButtonIcon } from '@molecules/ButtonIcon'
import { useRouter } from 'next/router'
import { H3 } from '@atoms/Title'

export type ContactFormProps = {
  title: string
  text: string
  buttonText: string
}

const formData = {
  en: {
    name: {
      required: 'Name is required',
      label: 'Name',
    },
    email: {
      required: 'Email is required',
      label: 'Email',
    },
    phone: {
      required: 'Phone is required',
      label: 'Phone',
    },
    message: {
      required: 'Message is required',
      label: 'Message',
    },
    error: 'Something went wrong, please try again later or contact us at hello@webthinx.com',
    success: 'Your message has been successfully sent. Thank you!',
  },
  cs: {
    name: {
      required: 'Jméno musí být vyplněno',
      label: 'Jméno',
    },
    email: {
      required: 'Email musí být vyplněn',
      label: 'Email',
    },
    phone: {
      required: 'Telefon musí být zadán',
      label: 'Telefon',
    },
    message: {
      required: 'Zpráva nesmí být prázdná',
      label: 'Zpráva',
    },
    error: 'Něco se pokazilo, zkuste to prosím později nebo nás kontaktujte na hello@webthinx.com',
    success: 'Vaše zpráva byla úspěšně odeslána, děkujeme!',
  },
  de: {
    name: {
      required: 'Name ist erforderlich',
      label: 'Name',
    },
    email: {
      required: 'E-Mail ist erforderlich',
      label: 'Email',
    },
    phone: {
      required: 'Telefon ist erforderlich',
      label: 'Telefon',
    },
    message: {
      required: 'Nachricht muss ausgefüllt werden',
      label: 'Nachricht',
    },
    error:
      'Etwas ist schief gelaufen, versuchen Sie es bitte später noch ein Mal oder kontaktieren Sie uns unter hello@webthinx.com',
    success: 'Ihre Nachricht wurde erfolgreich versendet, vielen Dank!',
  },
}

type formDataType = keyof typeof formData

export const ContactForm: FC<ContactFormProps> = ({ title, text, buttonText }) => {
  const router = useRouter()
  const path = router.asPath
  let locale: formDataType = 'en'
  if (router.locale === 'cs' || router.locale === 'de' || router.locale === 'en') {
    locale = router.locale
  }
  const data = formData[locale]
  const methods = useForm<ContactFormValues>()
  const [isFormSent, setIsFormSent] = useState(false)
  const [isFormSending, setIsFormSending] = useState(false)
  const [isFormError, setIsFormError] = useState(false)
  const errors = methods.formState.errors

  const onSubmit = async (data: ContactFormValues) => {
    if (data.email || data.name) {
      return false
    }
    const sendData = {
      name: data.g439x40,
      email: data.g439x41,
      phone: data.phone,
      message: data.message,
      path: path,
    }
    // eslint-disable-next-line no-console
    setIsFormSending(true)
    const response = await fetch('/api/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'wx-secret': process.env.NEXT_PUBLIC_WX_SECRET ?? '',
      },
      body: JSON.stringify(sendData),
    })
    await response.json().then((data) => {
      if (data.error) {
        setIsFormSending(false)
        setIsFormError(true)
        setIsFormSent(false)
      } else {
        setIsFormSending(false)
        setIsFormError(false)
        setIsFormSent(true)
      }
    })
  }

  //TODO translate
  return (
    <Container id="contactForm">
      <S.Wrap>
        <S.Title tabIndex={0}>{title}</S.Title>
        <S.FormWrap>
          <S.VisibilityWrap hidden={isFormSent}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <S.InputRow>
                  <S.FloatingInput error={!!errors.email} label="Name" type="text" name="name" honey={true} />
                  <S.FloatingInput error={!!errors.email} label="E-mail" type="text" name="email" honey={true} />
                  <S.FloatingInput
                    error={!!errors.name}
                    type={'text'}
                    label={data.name.label}
                    name={'g439x40'}
                    required={data.name.required}
                  />
                  <S.FloatingInput
                    error={!!errors.email}
                    type={'email'}
                    label={data.email.label}
                    name={'g439x41'}
                    required={data.email.required}
                  />
                  <S.FloatingInput
                    error={!!errors.phone}
                    type={'text'}
                    label={data.phone.label}
                    name={'phone'}
                    required={data.phone.required}
                  />
                </S.InputRow>
                <S.SecondRow>
                  <S.FloatingTextarea
                    name="message"
                    label={data.message.label}
                    rows={10}
                    required={data.message.required}
                  />
                  <S.ButtonWrap>
                    <S.Text>{text}</S.Text>
                    <S.SendError show={isFormError}>{data.error}</S.SendError>
                    <ButtonIcon
                      variant="primary"
                      moduleBackground={'grayDarker'}
                      type="submit"
                      disabled={isFormSending || isFormSent}
                      sendButton={{ loading: isFormSending }}
                    >
                      {buttonText}
                    </ButtonIcon>
                  </S.ButtonWrap>
                </S.SecondRow>
              </form>
            </FormProvider>
          </S.VisibilityWrap>
          <S.VisibilityWrap hidden={!isFormSent}>
            <H3>{data.success}</H3>
            <S.Text>{text}</S.Text>
          </S.VisibilityWrap>
        </S.FormWrap>
      </S.Wrap>
    </Container>
  )
}
