import { FC } from 'react'
import { IPositionEntity } from '@generated/index'
import * as S from './PositionCard.styled'
import { useRouter } from 'next/router'

type PositionCardProps = IPositionEntity & {
  className?: string
}

const textData = {
  en: {
    btnText: 'position detail',
  },
  cs: {
    btnText: 'detail pozice',
  },
  de: {
    btnText: 'position detail',
  },
}

type textDataType = keyof typeof textData

export const PositionCard: FC<PositionCardProps> = ({ name, slug, cardExcerpt, icon, className }) => {
  const router = useRouter()
  let locale: textDataType = 'en'
  if (router.locale === 'cs' || router.locale === 'de' || router.locale === 'en') {
    locale = router.locale
  }
  const data = textData[locale]
  return (
    <S.Wrap href={slug} className={className}>
      {icon?.code && <S.Icon dangerouslySetInnerHTML={{ __html: icon.code }} />}
      <S.Title>{name}</S.Title>
      {cardExcerpt && <S.Excerpt>{cardExcerpt}</S.Excerpt>}
      <S.FakeButton>
        {data.btnText}
        <S.FakeButtonIcon />
      </S.FakeButton>
    </S.Wrap>
  )
}
