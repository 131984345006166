import React, { FC } from 'react'
import { IComponentComponentsTestimonial } from '@generated/index'
import * as S from './Testimonial.styled'
import Image from 'next/image'
import { getStrapiRoot } from '@utils/env'
import { StrapiImageEntity } from '@strapi/strapiImage'

export type TestimonialProps = IComponentComponentsTestimonial & { image?: StrapiImageEntity }

export const Testimonial: FC<TestimonialProps> = ({ image, name, position, text }) => {
  let imageUrl = null

  if (image?.url) {
    imageUrl = getStrapiRoot() + image.url
  }

  return (
    <S.Wrap>
      {imageUrl && image && (
        <S.ImageWrap>
          <Image
            src={imageUrl}
            alt={image.alternativeText ?? 'testimonial image'}
            fill={true}
            style={{ objectFit: 'cover', objectPosition: 'top' }}
            placeholder={image.placeholder && image.placeholder !== null ? 'blur' : 'empty'}
            blurDataURL={image.placeholder}
            sizes={'180px'}
          />
        </S.ImageWrap>
      )}
      <S.Name>{name}</S.Name>
      <S.Position>{position}</S.Position>
      <S.Text>{text}</S.Text>
    </S.Wrap>
  )
}
