import styled from '@emotion/styled'

export const FormattedText = styled.div`
  h2 {
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.colors.secondary};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  h3 {
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 1.0625rem;
    line-height: 1.4;
    color: ${(props) => props.theme.colors.secondary};
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-bottom: 8px;
    margin-top: 24px;
  }
  p {
    line-height: 1.5;
    margin-bottom: 8px;
  }
  strong {
    color: ${(props) => props.theme.colors.secondary};
  }

  ul {
    margin-bottom: 1.5rem;
  }

  li {
    padding-left: 1rem;
    margin-bottom: 0.2rem;
    position: relative;
    list-style: none;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.primary};
    }
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
  }
`
