import { FC } from 'react'
import { Container } from '@atoms/Container'
import * as S from './PageHero.styled'
import { IComponentModulesPageHero } from '@generated/index'
import Image from 'next/image'
import { getStrapiRoot } from '@utils/env'
import { sizes } from '../../../themes/utils/breakpoints'

type PageHeroProps = IComponentModulesPageHero

export const PageHero: FC<PageHeroProps> = ({ title, button, subtitle, testimonial }) => {
  let imageUrl = null
  if (testimonial?.image?.url) {
    imageUrl = getStrapiRoot() + testimonial.image.url
  }
  return (
    <Container>
      <S.Wrap>
        <S.Testimonial>
          {imageUrl && (
            <S.ImageWrap>
              <Image
                src={imageUrl}
                alt={''}
                fill={true}
                priority
                sizes={`${sizes.tablet} 400px, ${sizes.desktop} 500px`}
              />
            </S.ImageWrap>
          )}
          {testimonial?.name && <S.TestimonialName>{testimonial.name}</S.TestimonialName>}
          {testimonial?.position && <S.TestimonialPosition>{testimonial.position}</S.TestimonialPosition>}
          {testimonial?.text && <S.TestimonialText>{testimonial.text}</S.TestimonialText>}
        </S.Testimonial>
        <S.TextWrap>
          <S.Title title={title}>{title}</S.Title>
          {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
          {button?.title && (
            <S.Button href={button.url} variant="primary" moduleBackground="grayLighter">
              {button.title}
            </S.Button>
          )}
        </S.TextWrap>
      </S.Wrap>
    </Container>
  )
}
