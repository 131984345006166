import styled from '@emotion/styled'
import { Container as ContainerComponent } from '../../atoms/Container'
import SVGBg from '@atoms/WxLogoBg/SVGBg.svg'
import { H2 } from '@atoms/Title'
import { mediaQuery } from 'themes/utils/breakpoints'

export const Container = styled(ContainerComponent)`
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;

  ${mediaQuery.desktop.up} {
    padding-top: 112px;
    padding-bottom: 130px;
  }
`

export const Background = styled.div`
  position: absolute;
  width: 400px;
  z-index: -1;
  left: 15%;
  top: 140px;
`;

export const BackgroundSVG = styled(SVGBg)`
  fill: ${({ theme }) => theme.colors.grayDarker};
  filter: drop-shadow(
    inset -2px -2px 3px rgba(255, 255, 255, 0.8),
    inset 2px 2px 3px rgba(0, 0, 0, 0.07)
  );
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  ${mediaQuery.tablet.up} {
    flex-direction: row;
  }

  ${mediaQuery.desktop.up} {
    justify-content: space-between;
  }
`;

export const TextWrap = styled.div`
  width: 100%;
  margin-bottom: 80px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery.tablet.up} {
    width: 50%;
    margin-bottom:0;
    align-items: flex-start;
  }

  ${mediaQuery.desktop.up} {
    width: 40%;
    max-width: 400px;
  }
`;

export const Title = styled(H2)`
  margin-bottom: 32px;
  text-align: center;

  ${mediaQuery.tablet.up} {
    text-align: left;
    margin-bottom: 56px;
  }
`;

export const Text = styled.div`
    margin-bottom: 48px;
    text-align: center;

    ${mediaQuery.tablet.up} {
        margin-bottom: 64px;
        text-align: left;
    }

    ${mediaQuery.desktop.up} {
        margin-bottom: 104px;
    }
`;

export const TestimonialWrap = styled.div`
  width: 100%;
  max-width: 400px;

  ${mediaQuery.tablet.up} {
    width: 50%;
    margin-left: 30px;
  }

  ${mediaQuery.desktop.up} {
    width: 60%;
    max-width: 600px;
  }
`;

export const ButtonWrap = styled.div``;

export const NumbersWrap = styled.div``;
