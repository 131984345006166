import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.grayLighter};
  border-radius: 20px;
  overflow: hidden;
  box-shadow: -10px -10px 20px #fff, -30px -30px 30px ${({ theme }) => theme.colors.grayLighter},
    10px 0px 30px rgba(0, 0, 0, 0.15), 10px -10px 30px ${({ theme }) => theme.colors.grayLighter};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  ${mediaQuery.tablet.up} {
    max-width: 100%;
  }

  box-shadow: -10px -10px 20px #fff, 10px 0px 30px rgba(0, 0, 0, 0.15),
    10px -10px 30px ${({ theme }) => theme.colors.grayLighter};
`

export const Item = styled.div`
  width: 100%;

  ${mediaQuery.tablet.up} {
    width: 50%;
  }
`;
