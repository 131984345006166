import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Viewport = styled.div`
  overflow: hidden;
  max-width: 100%;
`

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
`

export const Item = styled.div`
  aspect-ratio: 16/9;
  position: relative;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
  min-width: calc(100% / 1);
  margin-left: 5px;
  margin-right: 5px;

  &:hover {
    filter: grayscale(0);
  }

  ${mediaQuery.tabletXs.up} {
    min-width: calc(100% / 2);
  }

  ${mediaQuery.tablet.up} {
    min-width: calc(100% / 3);
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: 2400px) {
    min-width: calc(100% / 4);
  }

  @media (min-width: 3000px) {
    min-width: calc(100% / 5);
  }
`
