import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const InputGroup = styled.div<{ error: boolean }>`
  position: relative;
  box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 0.8), inset 3px 3px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  line-height: 0;

  * {
    line-height: 1.5;
  }
`

export const ErrorMessage = styled.div`
  padding-left: 37px;
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: rgba(128, 0, 0, 0.1);
  color: rgba(128, 0, 0, 0.5);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  ${mediaQuery.tablet.up} {
    padding-bottom: 4px;
  }
`

export const Label = styled.label<{ isFilled: boolean; error: boolean }>`
  position: absolute;
  top: 14px;
  left: 37px;
  font-size: 16px;
  color: ${(props) => (props.error ? '#800000' : props.theme.colors.primary)};
  font-weight: ${(props) => (props.error ? 'bold' : 'normal')};
  transition: all 0.2s ease-in-out;
  transform: translateY(0);
  z-index: 1;
  ${({ isFilled }) =>
    isFilled &&
    `
        transform: translateY(-6px);
        font-size: 12px;
    `}
`

export const Textarea = styled.textarea<{ error: boolean }>`
  padding: 30px 37px 10px;
  border: 0;
  width: 100%;
  z-index: 1;
  position: relative;
  max-width: 100%;
  box-shadow: none;
  background-color: ${(props) => (props.error ? 'rgba(128, 0, 0, 0.1)' : 'transparent')};
  border-radius: ${(props) => (props.error ? '0' : '10px')};
  resize: none;

  &:focus {
    ${(props) =>
      props.error
        ? 'box-shadow: none;'
        : 'box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 1), inset 3px 3px 5px rgba(0, 0, 0, 0.2)'};
    outline: 0;
  }

  &:focus + label {
    transform: translateY(-6px);
    font-size: 12px;
  }
`
