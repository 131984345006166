import { H2 } from '@atoms/Title'
import styled from '@emotion/styled'
import { mediaQuery } from 'themes/utils/breakpoints'
import { Container as ContainerComponent } from '../../atoms/Container'

export const ContainerFullWidth = styled(ContainerComponent)`
  padding: 0;
  width: 100%;
  max-width: 100%;
  padding-bottom: 60px;
  padding-top: 50px;

  ${mediaQuery.tablet.up} {
    padding-bottom: 120px;
    padding-top: 110px;
  }
`

export const TitleContainer = styled.div`
  max-width: ${({ theme }) => theme.container.maxWidth};
  padding: ${({ theme }) => theme.container.padding};
  margin: 0 auto;
`;

export const Title = styled(H2)`
  margin-bottom: 10px;
  width: 100%;
  text-align: center;

  ${mediaQuery.tablet.up} {
    margin-bottom: 62px;
  }

  ${mediaQuery.desktop.up} {
    text-align: left;
  }
`;

export const SlidersWrap = styled.div``;

export const InfoSliderWrap = styled.div``;
