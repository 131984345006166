import { IComponentComponentsBenefitIcon } from '@generated/index'
import { FC } from 'react'
import * as S from './BenefitIcon.styled'

type BenefitIconProps = IComponentComponentsBenefitIcon & { className?: string }

export const BenefitIcon: FC<BenefitIconProps> = ({ code, title, className }) => {
  return (
    <S.Wrap className={className}>
      <S.Icon dangerouslySetInnerHTML={{ __html: code }} />
      <S.Title>{title}</S.Title>
    </S.Wrap>
  )
}
