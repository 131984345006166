import { FC } from 'react'
import camelCase from 'lodash/camelCase'
import { contentModules } from '../organisms'
import * as S from './ModulesRenderer.styled'
//import * as console from 'console'

const NotFoundModule = (props: { name: string; path: string }) => (
  <div>
    Module `<strong>{props.name}</strong> ` was not found for {props.path}.
  </div>
)

type Module = {
  __component: string
  [value: string]: any
}

type ModulesRendererProps = {
  modules: Module[]
  withContainer?: boolean
  extendedModules?: Record<string, FC<any>>
}

export const ModulesRenderer: FC<ModulesRendererProps> = (props) => {
  const { modules, withContainer = true, extendedModules = {} } = props

  const getData = (componentName: string) => {
    const moduleName = componentName.replace('modules.', '')

    //const moduleName = componentName
    const getName = (name: string) => name.charAt(0).toUpperCase() + camelCase(name.slice(1))

    return {
      //groupName: groupName ? getName(groupName) : '<unknown>',
      moduleName: moduleName ? `${getName(moduleName)}` : '<unknown>',
    }
  }

  const renderModule = (module: Module, index: number) => {
    if (!module.__component) return

    const { moduleName } = getData(module.__component)

    // @ts-ignore
    let Module = contentModules?.[moduleName] || null

    if (extendedModules[module.__component]) Module = extendedModules[module.__component]

    if (Module) {
      return <Module key={index} {...module} />
    } else {
      return <NotFoundModule key={index} name={`${moduleName}`} path={module.__component} />
    }
  }

  const renderModules = () => (modules || []).map((module, index) => module && renderModule(module, index))
  return withContainer ? <S.Container>{renderModules()}</S.Container> : <>{renderModules()}</>
}
