import styled from '@emotion/styled'
import { TextColumn } from '@molecules/TextColumn'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 60px;

  ${mediaQuery.tabletXs.up} {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  ${mediaQuery.tablet.up} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${mediaQuery.desktop.up} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

export const Column = styled(TextColumn)`
  ${mediaQuery.tabletXs.up} {
    max-width: 518px;
    width: calc(50% - 20px);
  }
`
