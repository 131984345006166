import { FC } from 'react'
import { IComponentComponentsFloatingNumbers } from '@generated/index'
import * as S from './FloatingNumbers.styled'
import { FloatingNumber } from '@molecules/FloatingNumber'

export type FloatingNumbersProps = IComponentComponentsFloatingNumbers

export const FloatingNumbers: FC<FloatingNumbersProps> = ({ numbers }) => {
  return (
    <>
      <S.Wrap>
        {numbers.map((number, index) => (
          <FloatingNumber key={index} {...number} />
        ))}
      </S.Wrap>
    </>
  );
};
