import { FC } from 'react'
import * as S from './ServicesCards.styled'
import { Container } from '@atoms/Container'
import { IComponentModulesServicesCards } from '@generated/index'
import { ServiceCardsItem } from '@molecules/ServiceCardsItem'

export type ServicesCardsProps = IComponentModulesServicesCards

export const ServicesCards: FC<ServicesCardsProps> = ({ cards }) => {
  return (
    <Container>
      <S.Wrap>
        {cards?.map((card, index) => {
          return <ServiceCardsItem key={index} {...card} />
        })}
      </S.Wrap>
    </Container>
  )
}
