import { HeroHp } from './HeroHp'
import { ServicesCards } from './ServicesCards'
import { ReferencesLogos } from './ReferencesLogos'
import { ReferencesSlider } from './/ReferencesSlider'
import { AboutUs } from './AboutUs'
import { PositionsGrid } from './PositionsGrid'
import { ContactForm } from './ContactForm'
import { ReusableBlock } from './ReusableBlock'
import { Benefits } from './Benefits'
import { TwoColumnsText } from './TwoColumnsText'
import { GallerySlider } from './GallerySlider'
import { PageHero } from './PageHero'
import { IllustrationText } from './IllustrationText'
import { PositionsCards } from './PositionsCards'
import { TextArea } from './TextArea'

export const contentModules = {
  HeroHp: HeroHp,
  ServicesCards: ServicesCards,
  ReferencesLogos: ReferencesLogos,
  ReferencesSlider: ReferencesSlider,
  AboutUs: AboutUs,
  PositionsGrid: PositionsGrid,
  ContactForm: ContactForm,
  ReusableBlock: ReusableBlock,
  Benefits: Benefits,
  TwoColumnsText: TwoColumnsText,
  GallerySlider: GallerySlider,
  PageHero: PageHero,
  IllustrationText: IllustrationText,
  PositionsCards: PositionsCards,
  TextArea: TextArea,
}
