import { FC } from 'react'
import { Container } from '@atoms/Container'
import * as S from './Benefits.styled'
import { IComponentModulesBenefits } from '@generated/index'

export type BenefitsProps = IComponentModulesBenefits

export const Benefits: FC<BenefitsProps> = ({ title, icons }) => {
  return (
    <Container variant="white">
      <S.Wrap>
        <S.Title>{title}</S.Title>
        <S.Grid>
          {icons.map((icon, index) => (
            <S.Item key={index} {...icon} />
          ))}
        </S.Grid>
      </S.Wrap>
    </Container>
  )
}
