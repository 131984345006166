import React, { FC } from 'react'
import * as S from './ReferencesSlider.styled'
import { DeviceSlider } from '@molecules/DeviceSlider'
import { ComponentModulesReferencesSlider, Reference, Tag } from 'models/types'
import { StrapiImageEntity } from '@strapi/strapiImage'

type FixedReference = Reference & {
  logo: StrapiImageEntity
  mockupImage: StrapiImageEntity
  mockupImageMobile: StrapiImageEntity
  tags: Tag[]
}

export type ReferencesSliderProps = ComponentModulesReferencesSlider & {
  references: FixedReference[]
}

export const ReferencesSlider: FC<ReferencesSliderProps> = ({ title, references }) => {
  return (
    <S.ContainerFullWidth variant="white">
      <S.TitleContainer>
        <S.Title>{title}</S.Title>
      </S.TitleContainer>
      <S.SlidersWrap>
        <DeviceSlider references={references} />
      </S.SlidersWrap>
    </S.ContainerFullWidth>
  )
}
