import { useRouter } from 'next/router'
import { FC } from 'react'
import * as S from './TextGridItem.styled'

export type TextGridProps = {
  title: string
  text: string
  link: string
}

const textData = {
  en: {
    btnText: 'position detail',
  },
  cs: {
    btnText: 'detail pozice',
  },
  de: {
    btnText: 'position detail',
  },
}

type textDataType = keyof typeof textData

export const TextGridItem: FC<TextGridProps> = ({ title, text, link }) => {
  const router = useRouter()
  let locale: textDataType = 'en'
  if (router.locale === 'cs' || router.locale === 'de' || router.locale === 'en') {
    locale = router.locale
  }
  const data = textData[locale]
  return (
    <S.Wrap href={link}>
      <S.Title>{title}</S.Title>
      <S.Text>{text}</S.Text>
      <S.LinkWrap>
        <S.LinkText>{data.btnText}</S.LinkText>
        <S.ArrowIcon />
      </S.LinkWrap>
    </S.Wrap>
  )
}
