import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  box-shadow: -10px -10px 20px #fff, -30px -30px 30px ${({ theme }) => theme.colors.grayDarker},
    10px 0px 30px rgba(0, 0, 0, 0.4), 10px -10px 30px ${({ theme }) => theme.colors.grayDarker};
  background-color: ${({ theme }) => theme.colors.grayDarker};

  max-width: 500px;
  margin: 0 auto 112px;

  ${mediaQuery.tabletXs.up} {
    max-width: 800px;
  }

  ${mediaQuery.desktop.up} {
    max-width: 100%;
    box-shadow: 20px 20px 40px #cfcfcf, -20px -20px 70px rgba(255, 255, 255, 0.8);
  }
`
