import { FC } from 'react'
import { Container } from '@atoms/Container'
import * as S from './IllustrationText.styled'
import { FormattedText } from '@atoms/FormattedText'
import { IComponentModulesIllustrationText } from '@generated/index'
import { getStrapiRoot } from '@utils/env'
import Image from 'next/image'
import { sizes } from '../../../themes/utils/breakpoints'

type IllustrationTextProps = IComponentModulesIllustrationText

export const IllustrationText: FC<IllustrationTextProps> = ({ text, title, image }) => {
  let imageUrl = null
  if (image?.url) {
    imageUrl = getStrapiRoot() + image.url
  }

  return (
    <Container>
      <S.Wrap>
        <S.Title>{title}</S.Title>
        <S.InnerWrap>
          <S.TextWrap>{text && <FormattedText dangerouslySetInnerHTML={{ __html: text }} />}</S.TextWrap>
          <S.ImageWrap>
            {imageUrl && (
              <Image
                src={imageUrl}
                alt={''}
                fill={true}
                priority
                sizes={`${sizes.tablet} 400px, ${sizes.desktop} 500px`}
              />
            )}
          </S.ImageWrap>
        </S.InnerWrap>
      </S.Wrap>
    </Container>
  )
}
