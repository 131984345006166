import styled from '@emotion/styled'
import { H1Big, H3 } from '@atoms/Title'
import { ButtonIcon } from '@molecules/ButtonIcon'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  ${mediaQuery.tablet.up} {
    align-items: center;
    flex-direction: row;
    gap: 40px;
  }

  ${mediaQuery.desktop.up} {
    gap: 80px;
  }
`

export const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 2;
  margin-top: 20px;

  ${mediaQuery.tablet.up} {
    order: 0;
    width: 400px;
  }

  ${mediaQuery.desktop.up} {
    width: 600px;
  }
`

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ImageWrap = styled.div`
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1/1;
  position: relative;

  ${mediaQuery.tablet.up} {
    max-width: none;
  }
`

export const TestimonialName = styled(H3)``

export const TestimonialPosition = styled.div``

export const TestimonialText = styled.div``

export const Title = styled(H1Big)<{ title: string }>`
  position: relative;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 30px;
  display: block;

  &:after {
    content: '${(props) => props.title}';
    position: absolute;
    display: block;
    width: 100%;
    left: 30px;
    top: -10px;
    scale: 1.2;
    opacity: 0.05;
  }

  ${mediaQuery.tabletXs.up} {
    font-size: 4.5rem;
  }

  ${mediaQuery.desktop.up} {
    font-size: 6rem;
    margin-bottom: 44px;

    &:after {
      scale: 1.5;
      top: -30px;
      left: -75px;
    }
  }
`

export const Subtitle = styled(H3)``

export const Button = styled(ButtonIcon)`
  margin-top: 40px;
  margin-bottom: 40px;
`
