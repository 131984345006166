export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  HomepageContentDynamicZoneInput: any;
  PageContentDynamicZoneInput: any;
  PositionContentDynamicZoneInput: any;
  ReusableModuleModuleDynamicZoneInput: any;
  /** A string used to identify an i18n locale */
  I18NLocaleCode: any;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  total: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  pageCount: Scalars['Int'];
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  not?: InputMaybe<IdFilterInput>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  startsWith?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  contains?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  not?: InputMaybe<BooleanFilterInput>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  contains?: InputMaybe<Scalars['Boolean']>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  not?: InputMaybe<StringFilterInput>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  contains?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  not?: InputMaybe<IntFilterInput>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  startsWith?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  contains?: InputMaybe<Scalars['Int']>;
  notContains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  not?: InputMaybe<FloatFilterInput>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  startsWith?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  contains?: InputMaybe<Scalars['Float']>;
  notContains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  not?: InputMaybe<DateTimeFilterInput>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  contains?: InputMaybe<Scalars['DateTime']>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  not?: InputMaybe<JsonFilterInput>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  startsWith?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  contains?: InputMaybe<Scalars['JSON']>;
  notContains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  null?: InputMaybe<Scalars['Boolean']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
};

export type ComponentComponentsBenefitIconFiltersInput = {
  code?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentComponentsBenefitIconFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentComponentsBenefitIconFiltersInput>>>;
  not?: InputMaybe<ComponentComponentsBenefitIconFiltersInput>;
};

export type ComponentComponentsBenefitIcon = {
  __typename?: 'ComponentComponentsBenefitIcon';
  id: Scalars['ID'];
  code: Scalars['String'];
  title: Scalars['String'];
};

export type ComponentComponentsButton = {
  __typename?: 'ComponentComponentsButton';
  id: Scalars['ID'];
  title: Scalars['String'];
  isExternal: Scalars['Boolean'];
  page?: Maybe<PageEntityResponse>;
  url?: Maybe<Scalars['String']>;
};

export type ComponentComponentsFloatingNumbers = {
  __typename?: 'ComponentComponentsFloatingNumbers';
  id: Scalars['ID'];
  numbers: Array<Maybe<ComponentComponentsNumber>>;
};


export type ComponentComponentsFloatingNumbersNumbersArgs = {
  filters?: InputMaybe<ComponentComponentsNumberFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentComponentsGalleryItemFiltersInput = {
  title?: InputMaybe<StringFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentComponentsGalleryItemFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentComponentsGalleryItemFiltersInput>>>;
  not?: InputMaybe<ComponentComponentsGalleryItemFiltersInput>;
};

export type ComponentComponentsGalleryItem = {
  __typename?: 'ComponentComponentsGalleryItem';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  media: UploadFileEntityResponse;
};

export type ComponentComponentsLinkFiltersInput = {
  title?: InputMaybe<StringFilterInput>;
  isExternal?: InputMaybe<BooleanFilterInput>;
  page?: InputMaybe<PageFiltersInput>;
  url?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentComponentsLinkFiltersInput>>>;
  not?: InputMaybe<ComponentComponentsLinkFiltersInput>;
};

export type ComponentComponentsLink = {
  __typename?: 'ComponentComponentsLink';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  isExternal: Scalars['Boolean'];
  page?: Maybe<PageEntityResponse>;
  url?: Maybe<Scalars['String']>;
};

export type ComponentComponentsListItemFiltersInput = {
  title?: InputMaybe<StringFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentComponentsListItemFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentComponentsListItemFiltersInput>>>;
  not?: InputMaybe<ComponentComponentsListItemFiltersInput>;
};

export type ComponentComponentsListItem = {
  __typename?: 'ComponentComponentsListItem';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ComponentComponentsNumberFiltersInput = {
  numberValue?: InputMaybe<IntFilterInput>;
  subfix?: InputMaybe<StringFilterInput>;
  prefix?: InputMaybe<StringFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentComponentsNumberFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentComponentsNumberFiltersInput>>>;
  not?: InputMaybe<ComponentComponentsNumberFiltersInput>;
};

export type ComponentComponentsNumber = {
  __typename?: 'ComponentComponentsNumber';
  id: Scalars['ID'];
  numberValue: Scalars['Int'];
  subfix?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type ComponentComponentsServiceCardFiltersInput = {
  svg?: InputMaybe<ComponentComponentsSvgFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentComponentsLinkFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentComponentsServiceCardFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentComponentsServiceCardFiltersInput>>>;
  not?: InputMaybe<ComponentComponentsServiceCardFiltersInput>;
};

export type ComponentComponentsServiceCard = {
  __typename?: 'ComponentComponentsServiceCard';
  id: Scalars['ID'];
  svg?: Maybe<ComponentComponentsSvg>;
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  link?: Maybe<ComponentComponentsLink>;
};

export type ComponentComponentsSvgFiltersInput = {
  code?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentComponentsSvgFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentComponentsSvgFiltersInput>>>;
  not?: InputMaybe<ComponentComponentsSvgFiltersInput>;
};

export type ComponentComponentsSvgInput = {
  id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
};

export type ComponentComponentsSvg = {
  __typename?: 'ComponentComponentsSvg';
  id: Scalars['ID'];
  code: Scalars['String'];
};

export type ComponentComponentsTestimonial = {
  __typename?: 'ComponentComponentsTestimonial';
  id: Scalars['ID'];
  image?: Maybe<UploadFileEntityResponse>;
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ComponentComponentsTextColumnFiltersInput = {
  title?: InputMaybe<StringFilterInput>;
  icon?: InputMaybe<StringFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  textAfter?: InputMaybe<StringFilterInput>;
  listItems?: InputMaybe<ComponentComponentsListItemFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentComponentsTextColumnFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentComponentsTextColumnFiltersInput>>>;
  not?: InputMaybe<ComponentComponentsTextColumnFiltersInput>;
};

export type ComponentComponentsTextColumn = {
  __typename?: 'ComponentComponentsTextColumn';
  id: Scalars['ID'];
  title: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textAfter?: Maybe<Scalars['String']>;
  listItems?: Maybe<Array<Maybe<ComponentComponentsListItem>>>;
};


export type ComponentComponentsTextColumnListItemsArgs = {
  filters?: InputMaybe<ComponentComponentsListItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesAboutUs = {
  __typename?: 'ComponentModulesAboutUs';
  id: Scalars['ID'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  button?: Maybe<ComponentComponentsButton>;
  testimonial?: Maybe<ComponentComponentsTestimonial>;
  floatingNumbers?: Maybe<ComponentComponentsFloatingNumbers>;
};

export type ComponentModulesBenefits = {
  __typename?: 'ComponentModulesBenefits';
  id: Scalars['ID'];
  title: Scalars['String'];
  icons: Array<Maybe<ComponentComponentsBenefitIcon>>;
};


export type ComponentModulesBenefitsIconsArgs = {
  filters?: InputMaybe<ComponentComponentsBenefitIconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesContactForm = {
  __typename?: 'ComponentModulesContactForm';
  id: Scalars['ID'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  buttonText: Scalars['String'];
};

export type ComponentModulesGallerySlider = {
  __typename?: 'ComponentModulesGallerySlider';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  items: Array<Maybe<ComponentComponentsGalleryItem>>;
};


export type ComponentModulesGallerySliderItemsArgs = {
  filters?: InputMaybe<ComponentComponentsGalleryItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesHeroHp = {
  __typename?: 'ComponentModulesHeroHp';
  id: Scalars['ID'];
  titleLine1: Scalars['String'];
  image?: Maybe<ComponentComponentsSvg>;
  titleLine2?: Maybe<Scalars['String']>;
  titleLine3?: Maybe<Scalars['String']>;
  button?: Maybe<ComponentComponentsButton>;
};

export type ComponentModulesIllustrationText = {
  __typename?: 'ComponentModulesIllustrationText';
  id: Scalars['ID'];
  title: Scalars['String'];
  text: Scalars['String'];
  image?: Maybe<UploadFileEntityResponse>;
};

export type ComponentModulesPageHero = {
  __typename?: 'ComponentModulesPageHero';
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  button?: Maybe<ComponentComponentsButton>;
  testimonial?: Maybe<ComponentComponentsTestimonial>;
};

export type ComponentModulesPositionsCards = {
  __typename?: 'ComponentModulesPositionsCards';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  positions?: Maybe<PositionRelationResponseCollection>;
};


export type ComponentModulesPositionsCardsPositionsArgs = {
  filters?: InputMaybe<PositionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type ComponentModulesPositionsGrid = {
  __typename?: 'ComponentModulesPositionsGrid';
  id: Scalars['ID'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  button?: Maybe<ComponentComponentsButton>;
  positions?: Maybe<PositionRelationResponseCollection>;
};


export type ComponentModulesPositionsGridPositionsArgs = {
  filters?: InputMaybe<PositionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type ComponentModulesReferencesLogos = {
  __typename?: 'ComponentModulesReferencesLogos';
  id: Scalars['ID'];
  references?: Maybe<ReferenceRelationResponseCollection>;
  title?: Maybe<Scalars['String']>;
};


export type ComponentModulesReferencesLogosReferencesArgs = {
  filters?: InputMaybe<ReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type ComponentModulesReferencesSlider = {
  __typename?: 'ComponentModulesReferencesSlider';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  references?: Maybe<ReferenceRelationResponseCollection>;
};


export type ComponentModulesReferencesSliderReferencesArgs = {
  filters?: InputMaybe<ReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type ComponentModulesReusableBlock = {
  __typename?: 'ComponentModulesReusableBlock';
  id: Scalars['ID'];
  reusable_block?: Maybe<ReusableModuleEntityResponse>;
};

export type ComponentModulesServicesCards = {
  __typename?: 'ComponentModulesServicesCards';
  id: Scalars['ID'];
  cards?: Maybe<Array<Maybe<ComponentComponentsServiceCard>>>;
};


export type ComponentModulesServicesCardsCardsArgs = {
  filters?: InputMaybe<ComponentComponentsServiceCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentModulesTextArea = {
  __typename?: 'ComponentModulesTextArea';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ComponentModulesTwoColumnsText = {
  __typename?: 'ComponentModulesTwoColumnsText';
  id: Scalars['ID'];
  columns?: Maybe<Array<Maybe<ComponentComponentsTextColumn>>>;
};


export type ComponentModulesTwoColumnsTextColumnsArgs = {
  filters?: InputMaybe<ComponentComponentsTextColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentSharedContactInput = {
  id?: InputMaybe<Scalars['ID']>;
  address?: InputMaybe<Scalars['String']>;
  ic?: InputMaybe<Scalars['String']>;
  dic?: InputMaybe<Scalars['String']>;
  telNumber?: InputMaybe<Scalars['String']>;
  mail?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  facebookUrl?: InputMaybe<Scalars['String']>;
};

export type ComponentSharedContact = {
  __typename?: 'ComponentSharedContact';
  id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  dic?: Maybe<Scalars['String']>;
  telNumber?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
};

export type ComponentSharedFastContactFormInput = {
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  textButton?: InputMaybe<Scalars['String']>;
};

export type ComponentSharedFastContactForm = {
  __typename?: 'ComponentSharedFastContactForm';
  id: Scalars['ID'];
  title: Scalars['String'];
  text: Scalars['String'];
  textButton: Scalars['String'];
};

export type ComponentSharedFooterInput = {
  id?: InputMaybe<Scalars['ID']>;
  navTitle1?: InputMaybe<Scalars['String']>;
  navTitle2?: InputMaybe<Scalars['String']>;
  navId1?: InputMaybe<Scalars['Int']>;
  navId2?: InputMaybe<Scalars['Int']>;
  addressTitle?: InputMaybe<Scalars['String']>;
  copyrightText?: InputMaybe<Scalars['String']>;
};

export type ComponentSharedFooter = {
  __typename?: 'ComponentSharedFooter';
  id: Scalars['ID'];
  navTitle1?: Maybe<Scalars['String']>;
  navTitle2?: Maybe<Scalars['String']>;
  navId1: Scalars['Int'];
  navId2: Scalars['Int'];
  addressTitle?: Maybe<Scalars['String']>;
  copyrightText?: Maybe<Scalars['String']>;
};

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter'
}

export type ComponentSharedMetaSocialFiltersInput = {
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
};

export type ComponentSharedMetaSocialInput = {
  id?: InputMaybe<Scalars['ID']>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
};

export type ComponentSharedMetaSocial = {
  __typename?: 'ComponentSharedMetaSocial';
  id: Scalars['ID'];
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<UploadFileEntityResponse>;
};

export type ComponentSharedSeoFiltersInput = {
  metaTitle?: InputMaybe<StringFilterInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaSocial?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  keywords?: InputMaybe<StringFilterInput>;
  metaRobots?: InputMaybe<StringFilterInput>;
  structuredData?: InputMaybe<JsonFilterInput>;
  canonicalURL?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  not?: InputMaybe<ComponentSharedSeoFiltersInput>;
};

export type ComponentSharedSeoInput = {
  id?: InputMaybe<Scalars['ID']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaImage?: InputMaybe<Scalars['ID']>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  keywords?: InputMaybe<Scalars['String']>;
  metaRobots?: InputMaybe<Scalars['String']>;
  structuredData?: InputMaybe<Scalars['JSON']>;
  canonicalURL?: InputMaybe<Scalars['String']>;
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  id: Scalars['ID'];
  metaTitle: Scalars['String'];
  metaDescription: Scalars['String'];
  metaImage: UploadFileEntityResponse;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  keywords?: Maybe<Scalars['String']>;
  metaRobots?: Maybe<Scalars['String']>;
  structuredData?: Maybe<Scalars['JSON']>;
  canonicalURL?: Maybe<Scalars['String']>;
};


export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFileFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  alternativeText?: InputMaybe<StringFilterInput>;
  caption?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  placeholder?: InputMaybe<StringFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  not?: InputMaybe<UploadFileFiltersInput>;
};

export type UploadFileInput = {
  name?: InputMaybe<Scalars['String']>;
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  mime?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  name: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
  mime: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  placeholder?: Maybe<Scalars['String']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFile>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolderFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  not?: InputMaybe<UploadFolderFiltersInput>;
};

export type UploadFolderInput = {
  name?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<Scalars['ID']>;
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  path?: InputMaybe<Scalars['String']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  name: Scalars['String'];
  pathId: Scalars['Int'];
  parent?: Maybe<UploadFolderEntityResponse>;
  children?: Maybe<UploadFolderRelationResponseCollection>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFolder>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type I18NLocaleFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<I18NLocale>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsPermissionFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  action?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsPermission>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRoleFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsRole>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  username?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  password?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  blocked?: InputMaybe<BooleanFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsUserInput = {
  username?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['ID']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  username: Scalars['String'];
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsUser>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type GlobalInput = {
  contact?: InputMaybe<ComponentSharedContactInput>;
  footer?: InputMaybe<ComponentSharedFooterInput>;
  fastContactForm?: InputMaybe<ComponentSharedFastContactFormInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type Global = {
  __typename?: 'Global';
  contact?: Maybe<ComponentSharedContact>;
  footer?: Maybe<ComponentSharedFooter>;
  fastContactForm?: Maybe<ComponentSharedFastContactForm>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  localizations?: Maybe<GlobalRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
};

export type GlobalEntity = {
  __typename?: 'GlobalEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Global>;
};

export type GlobalEntityResponse = {
  __typename?: 'GlobalEntityResponse';
  data?: Maybe<GlobalEntity>;
};

export type GlobalRelationResponseCollection = {
  __typename?: 'GlobalRelationResponseCollection';
  data: Array<GlobalEntity>;
};

export type HomepageContentDynamicZone = ComponentModulesHeroHp | ComponentModulesServicesCards | ComponentModulesReferencesLogos | ComponentModulesReferencesSlider | ComponentModulesAboutUs | ComponentModulesPositionsGrid | ComponentModulesContactForm | ComponentModulesReusableBlock | Error;

export type HomepageInput = {
  content?: InputMaybe<Array<Scalars['HomepageContentDynamicZoneInput']>>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage = {
  __typename?: 'Homepage';
  content?: Maybe<Array<Maybe<HomepageContentDynamicZone>>>;
  seo: ComponentSharedSeo;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localizations?: Maybe<HomepageRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
};


export type HomepageLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type HomepageEntity = {
  __typename?: 'HomepageEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Homepage>;
};

export type HomepageEntityResponse = {
  __typename?: 'HomepageEntityResponse';
  data?: Maybe<HomepageEntity>;
};

export type HomepageRelationResponseCollection = {
  __typename?: 'HomepageRelationResponseCollection';
  data: Array<HomepageEntity>;
};

export type PageContentDynamicZone = ComponentModulesReusableBlock | ComponentModulesAboutUs | ComponentModulesBenefits | ComponentModulesContactForm | ComponentModulesGallerySlider | ComponentModulesPageHero | ComponentModulesPositionsGrid | ComponentModulesReferencesLogos | ComponentModulesReferencesSlider | ComponentModulesServicesCards | ComponentModulesTwoColumnsText | ComponentModulesIllustrationText | ComponentModulesPositionsCards | ComponentModulesTextArea | Error;

export type PageFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  localizations?: InputMaybe<PageFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  not?: InputMaybe<PageFiltersInput>;
};

export type PageInput = {
  slug?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Array<Scalars['PageContentDynamicZoneInput']>>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type Page = {
  __typename?: 'Page';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<PageContentDynamicZone>>>;
  seo: ComponentSharedSeo;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localizations?: Maybe<PageRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
};


export type PageLocalizationsArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type PageEntity = {
  __typename?: 'PageEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Page>;
};

export type PageEntityResponse = {
  __typename?: 'PageEntityResponse';
  data?: Maybe<PageEntity>;
};

export type PageEntityResponseCollection = {
  __typename?: 'PageEntityResponseCollection';
  data: Array<PageEntity>;
  meta: ResponseCollectionMeta;
};

export type PageRelationResponseCollection = {
  __typename?: 'PageRelationResponseCollection';
  data: Array<PageEntity>;
};

export type PositionContentDynamicZone = ComponentModulesAboutUs | ComponentModulesContactForm | ComponentModulesHeroHp | ComponentModulesPositionsGrid | ComponentModulesReferencesLogos | ComponentModulesReferencesSlider | ComponentModulesReusableBlock | ComponentModulesServicesCards | Error;

export type PositionFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  icon?: InputMaybe<ComponentComponentsSvgFiltersInput>;
  cardExcerpt?: InputMaybe<StringFilterInput>;
  subtitle?: InputMaybe<StringFilterInput>;
  mainText?: InputMaybe<StringFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  localizations?: InputMaybe<PositionFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<PositionFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<PositionFiltersInput>>>;
  not?: InputMaybe<PositionFiltersInput>;
};

export type PositionInput = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<ComponentComponentsSvgInput>;
  cardExcerpt?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  mainText?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Array<Scalars['PositionContentDynamicZoneInput']>>;
  image?: InputMaybe<Scalars['ID']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type Position = {
  __typename?: 'Position';
  name: Scalars['String'];
  slug: Scalars['String'];
  excerpt?: Maybe<Scalars['String']>;
  icon?: Maybe<ComponentComponentsSvg>;
  cardExcerpt?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  mainText?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<PositionContentDynamicZone>>>;
  image: UploadFileEntityResponse;
  seo: ComponentSharedSeo;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localizations?: Maybe<PositionRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
};


export type PositionLocalizationsArgs = {
  filters?: InputMaybe<PositionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type PositionEntity = {
  __typename?: 'PositionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Position>;
};

export type PositionEntityResponse = {
  __typename?: 'PositionEntityResponse';
  data?: Maybe<PositionEntity>;
};

export type PositionEntityResponseCollection = {
  __typename?: 'PositionEntityResponseCollection';
  data: Array<PositionEntity>;
  meta: ResponseCollectionMeta;
};

export type PositionRelationResponseCollection = {
  __typename?: 'PositionRelationResponseCollection';
  data: Array<PositionEntity>;
};

export type ReferenceFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  logoSvg?: InputMaybe<ComponentComponentsSvgFiltersInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  tags?: InputMaybe<TagFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  localizations?: InputMaybe<ReferenceFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ReferenceFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ReferenceFiltersInput>>>;
  not?: InputMaybe<ReferenceFiltersInput>;
};

export type ReferenceInput = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['ID']>;
  logoSvg?: InputMaybe<ComponentComponentsSvgInput>;
  excerpt?: InputMaybe<Scalars['String']>;
  mockupImage?: InputMaybe<Scalars['ID']>;
  mockupImageMobile?: InputMaybe<Scalars['ID']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type Reference = {
  __typename?: 'Reference';
  name?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  logo?: Maybe<UploadFileEntityResponse>;
  logoSvg: ComponentComponentsSvg;
  excerpt?: Maybe<Scalars['String']>;
  mockupImage?: Maybe<UploadFileEntityResponse>;
  mockupImageMobile?: Maybe<UploadFileEntityResponse>;
  seo: ComponentSharedSeo;
  tags?: Maybe<TagRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localizations?: Maybe<ReferenceRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
};


export type ReferenceTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ReferenceLocalizationsArgs = {
  filters?: InputMaybe<ReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};

export type ReferenceEntity = {
  __typename?: 'ReferenceEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Reference>;
};

export type ReferenceEntityResponse = {
  __typename?: 'ReferenceEntityResponse';
  data?: Maybe<ReferenceEntity>;
};

export type ReferenceEntityResponseCollection = {
  __typename?: 'ReferenceEntityResponseCollection';
  data: Array<ReferenceEntity>;
  meta: ResponseCollectionMeta;
};

export type ReferenceRelationResponseCollection = {
  __typename?: 'ReferenceRelationResponseCollection';
  data: Array<ReferenceEntity>;
};

export type ReusableModuleModuleDynamicZone = ComponentModulesAboutUs | ComponentModulesContactForm | ComponentModulesHeroHp | ComponentModulesPositionsGrid | ComponentModulesReferencesLogos | ComponentModulesReferencesSlider | ComponentModulesServicesCards | ComponentModulesBenefits | ComponentModulesTwoColumnsText | ComponentModulesGallerySlider | Error;

export type ReusableModuleFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  localizations?: InputMaybe<ReusableModuleFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ReusableModuleFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<ReusableModuleFiltersInput>>>;
  not?: InputMaybe<ReusableModuleFiltersInput>;
};

export type ReusableModuleInput = {
  name?: InputMaybe<Scalars['String']>;
  module?: InputMaybe<Array<Scalars['ReusableModuleModuleDynamicZoneInput']>>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type ReusableModule = {
  __typename?: 'ReusableModule';
  name?: Maybe<Scalars['String']>;
  module: Array<Maybe<ReusableModuleModuleDynamicZone>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  localizations?: Maybe<ReusableModuleRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
};


export type ReusableModuleLocalizationsArgs = {
  filters?: InputMaybe<ReusableModuleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReusableModuleEntity = {
  __typename?: 'ReusableModuleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<ReusableModule>;
};

export type ReusableModuleEntityResponse = {
  __typename?: 'ReusableModuleEntityResponse';
  data?: Maybe<ReusableModuleEntity>;
};

export type ReusableModuleEntityResponseCollection = {
  __typename?: 'ReusableModuleEntityResponseCollection';
  data: Array<ReusableModuleEntity>;
  meta: ResponseCollectionMeta;
};

export type ReusableModuleRelationResponseCollection = {
  __typename?: 'ReusableModuleRelationResponseCollection';
  data: Array<ReusableModuleEntity>;
};

export type TagFiltersInput = {
  id?: InputMaybe<IdFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  references?: InputMaybe<ReferenceFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  localizations?: InputMaybe<TagFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<TagFiltersInput>>>;
  or?: InputMaybe<Array<InputMaybe<TagFiltersInput>>>;
  not?: InputMaybe<TagFiltersInput>;
};

export type TagInput = {
  title?: InputMaybe<Scalars['String']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']>;
};

export type Tag = {
  __typename?: 'Tag';
  title?: Maybe<Scalars['String']>;
  references?: Maybe<ReferenceRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  localizations?: Maybe<TagRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
};


export type TagReferencesArgs = {
  filters?: InputMaybe<ReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
};


export type TagLocalizationsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TagEntity = {
  __typename?: 'TagEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Tag>;
};

export type TagEntityResponse = {
  __typename?: 'TagEntityResponse';
  data?: Maybe<TagEntity>;
};

export type TagEntityResponseCollection = {
  __typename?: 'TagEntityResponseCollection';
  data: Array<TagEntity>;
  meta: ResponseCollectionMeta;
};

export type TagRelationResponseCollection = {
  __typename?: 'TagRelationResponseCollection';
  data: Array<TagEntity>;
};

export type GenericMorph = ComponentComponentsBenefitIcon | ComponentComponentsButton | ComponentComponentsFloatingNumbers | ComponentComponentsGalleryItem | ComponentComponentsLink | ComponentComponentsListItem | ComponentComponentsNumber | ComponentComponentsServiceCard | ComponentComponentsSvg | ComponentComponentsTestimonial | ComponentComponentsTextColumn | ComponentModulesAboutUs | ComponentModulesBenefits | ComponentModulesContactForm | ComponentModulesGallerySlider | ComponentModulesHeroHp | ComponentModulesIllustrationText | ComponentModulesPageHero | ComponentModulesPositionsCards | ComponentModulesPositionsGrid | ComponentModulesReferencesLogos | ComponentModulesReferencesSlider | ComponentModulesReusableBlock | ComponentModulesServicesCards | ComponentModulesTextArea | ComponentModulesTwoColumnsText | ComponentSharedContact | ComponentSharedFastContactForm | ComponentSharedFooter | ComponentSharedMetaSocial | ComponentSharedSeo | UploadFile | UploadFolder | I18NLocale | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | Global | Homepage | Page | Position | Reference | ReusableModule | Tag;

export type FileInfoInput = {
  name?: InputMaybe<Scalars['String']>;
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  id: Scalars['ID'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsMeRole>;
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsRegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type NavigationItemRelated = Page;

export type NavigationItemRelatedData = {
  __typename?: 'NavigationItemRelatedData';
  id: Scalars['Int'];
  attributes?: Maybe<NavigationItemRelated>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  id: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  externalPath?: Maybe<Scalars['String']>;
  uiRouterKey: Scalars['String'];
  menuAttached: Scalars['Boolean'];
  order: Scalars['Int'];
  parent?: Maybe<NavigationItem>;
  master?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  related?: Maybe<NavigationItemRelatedData>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export enum NavigationRenderType {
  Flat = 'FLAT',
  Tree = 'TREE',
  Rfr = 'RFR'
}

export type PaginationArg = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  global?: Maybe<GlobalEntityResponse>;
  homepage?: Maybe<HomepageEntityResponse>;
  page?: Maybe<PageEntityResponse>;
  pages?: Maybe<PageEntityResponseCollection>;
  position?: Maybe<PositionEntityResponse>;
  positions?: Maybe<PositionEntityResponseCollection>;
  reference?: Maybe<ReferenceEntityResponse>;
  references?: Maybe<ReferenceEntityResponseCollection>;
  reusableModule?: Maybe<ReusableModuleEntityResponse>;
  reusableModules?: Maybe<ReusableModuleEntityResponseCollection>;
  tag?: Maybe<TagEntityResponse>;
  tags?: Maybe<TagEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  renderNavigationChild: Array<Maybe<NavigationItem>>;
  renderNavigation: Array<Maybe<NavigationItem>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGlobalArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryHomepageArgs = {
  publicationState?: InputMaybe<PublicationState>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryPageArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryPagesArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryPositionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryPositionsArgs = {
  filters?: InputMaybe<PositionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryReferenceArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryReferencesArgs = {
  filters?: InputMaybe<ReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationState?: InputMaybe<PublicationState>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryReusableModuleArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryReusableModulesArgs = {
  filters?: InputMaybe<ReusableModuleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryRenderNavigationChildArgs = {
  id: Scalars['String'];
  childUiKey: Scalars['String'];
  type?: InputMaybe<NavigationRenderType>;
  menuOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRenderNavigationArgs = {
  navigationIdOrSlug: Scalars['String'];
  type?: InputMaybe<NavigationRenderType>;
  menuOnly?: InputMaybe<Scalars['Boolean']>;
  path?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateGlobal?: Maybe<GlobalEntityResponse>;
  deleteGlobal?: Maybe<GlobalEntityResponse>;
  updateHomepage?: Maybe<HomepageEntityResponse>;
  deleteHomepage?: Maybe<HomepageEntityResponse>;
  createPage?: Maybe<PageEntityResponse>;
  updatePage?: Maybe<PageEntityResponse>;
  deletePage?: Maybe<PageEntityResponse>;
  createPosition?: Maybe<PositionEntityResponse>;
  updatePosition?: Maybe<PositionEntityResponse>;
  deletePosition?: Maybe<PositionEntityResponse>;
  createReference?: Maybe<ReferenceEntityResponse>;
  updateReference?: Maybe<ReferenceEntityResponse>;
  deleteReference?: Maybe<ReferenceEntityResponse>;
  createReusableModule?: Maybe<ReusableModuleEntityResponse>;
  updateReusableModule?: Maybe<ReusableModuleEntityResponse>;
  deleteReusableModule?: Maybe<ReusableModuleEntityResponse>;
  createTag?: Maybe<TagEntityResponse>;
  updateTag?: Maybe<TagEntityResponse>;
  deleteTag?: Maybe<TagEntityResponse>;
  upload: UploadFileEntityResponse;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  updateFileInfo: UploadFileEntityResponse;
  removeFile?: Maybe<UploadFileEntityResponse>;
  createGlobalLocalization?: Maybe<GlobalEntityResponse>;
  createHomepageLocalization?: Maybe<HomepageEntityResponse>;
  createPageLocalization?: Maybe<PageEntityResponse>;
  createPositionLocalization?: Maybe<PositionEntityResponse>;
  createReferenceLocalization?: Maybe<ReferenceEntityResponse>;
  createReusableModuleLocalization?: Maybe<ReusableModuleEntityResponse>;
  createTagLocalization?: Maybe<TagEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  login: UsersPermissionsLoginPayload;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationUpdateUploadFileArgs = {
  id: Scalars['ID'];
  data: UploadFileInput;
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationUpdateUploadFolderArgs = {
  id: Scalars['ID'];
  data: UploadFolderInput;
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateGlobalArgs = {
  data: GlobalInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteGlobalArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateHomepageArgs = {
  data: HomepageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteHomepageArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreatePageArgs = {
  data: PageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdatePageArgs = {
  id: Scalars['ID'];
  data: PageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeletePageArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreatePositionArgs = {
  data: PositionInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdatePositionArgs = {
  id: Scalars['ID'];
  data: PositionInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeletePositionArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateReferenceArgs = {
  data: ReferenceInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateReferenceArgs = {
  id: Scalars['ID'];
  data: ReferenceInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteReferenceArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateReusableModuleArgs = {
  data: ReusableModuleInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateReusableModuleArgs = {
  id: Scalars['ID'];
  data: ReusableModuleInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteReusableModuleArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateTagArgs = {
  data: TagInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateTagArgs = {
  id: Scalars['ID'];
  data: TagInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUploadArgs = {
  refId?: InputMaybe<Scalars['ID']>;
  ref?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<FileInfoInput>;
  file: Scalars['Upload'];
};


export type MutationMultipleUploadArgs = {
  refId?: InputMaybe<Scalars['ID']>;
  ref?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateGlobalLocalizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<GlobalInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateHomepageLocalizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<HomepageInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreatePageLocalizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<PageInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreatePositionLocalizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<PositionInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateReferenceLocalizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<ReferenceInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateReusableModuleLocalizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<ReusableModuleInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateTagLocalizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<TagInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsRoleInput;
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationUpdateUsersPermissionsUserArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsUserInput;
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  code: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};
