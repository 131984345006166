import { FC } from 'react'
import * as S from './GallerySlider.styled'
import { DynamicImageSlider } from '@molecules/DynamicImageSlider'
import { IComponentModulesGallerySlider } from '@generated/index'
import { Container } from '@atoms/Container'

export type GallerySliderProps = IComponentModulesGallerySlider

export const GallerySlider: FC<GallerySliderProps> = ({ title, items }) => {
  return (
    <S.Wrap>
      {title && (
        <Container>
          {' '}
          <S.Title>fdgfkjlaflgk klajglkag</S.Title>
        </Container>
      )}
      <DynamicImageSlider items={items} />
    </S.Wrap>
  )
}
