import { FC } from 'react'
import * as S from './FloatingTextarea.styled'
import { useFormContext } from 'react-hook-form'

export type FloatingTextareaProps = {
  label: string
  name: string
  className?: string
  rows?: number
  cols?: number
  required?: boolean | string
}

export const FloatingTextarea: FC<FloatingTextareaProps> = ({
  label,
  name,
  className,
  rows,
  cols,
  required = false,
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const value = watch(name)
  return (
    <S.InputGroup className={className} error={!!errors[name]}>
      <S.Textarea
        id={name}
        rows={rows}
        cols={cols}
        {...register(name, { required: required })}
        error={!!errors[name]}
        aria-invalid={!!errors[name]}
      />
      <S.Label htmlFor={name} isFilled={!!value} error={!!errors[name]}>
        {label}
      </S.Label>
      {/* @ts-ignore */}
      {errors?.[name] && <S.ErrorMessage>{errors?.[name]?.message ? errors?.[name]?.message : ''}</S.ErrorMessage>}
    </S.InputGroup>
  )
}
