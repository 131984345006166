import { H3 } from '@atoms/Title'
import styled from '@emotion/styled'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ImageWrap = styled.div`
  width: 200px;
  aspect-ratio: 1/1;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid ${({ theme }) => theme.colors.grayDarker};
  margin-bottom: 36px;
  box-shadow: 
    -8px -8px 14px #fff,
    -0px -0px 20px #fff,
    -8px -8px 20px rgba(0, 0, 0, 0.1),
    8px 8px 14px rgba(0, 0, 0, 0.05),
    0px 8px 14px rgba(0, 0, 0, 0.05),
    -8px 8px 20px rgba(0, 0, 0, 0.05)
    ;

    img {
        filter: grayscale(100%);
    }
`;

export const Name = styled(H3)`
  text-align: center;
  margin-bottom: 6px;
`;

export const Position = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;

export const Text = styled.div`
  text-align: center;
`;
