import React, { FC, useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import * as S from './DeviceSlider.styled'
import { DeviceSliderItem } from './DeviceSliderItem'
import { Reference, Tag } from 'models/types'
import { StrapiImageEntity } from '@strapi/strapiImage'

type FixedReference = Reference & {
  logo: StrapiImageEntity
  mockupImage: StrapiImageEntity
  mockupImageMobile: StrapiImageEntity
  tags: Tag[]
}

type DeviceSliderProps = {
  references: FixedReference[]
}

const SCALE_FACTOR = 3

const numberWithinRange = (number: number, min: number, max: number) => Math.min(Math.max(number, min), max)

export const DeviceSlider: FC<DeviceSliderProps> = ({ references }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: false,
    skipSnaps: false,
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [scaleValues, setScaleValues] = useState([])

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  const onScroll = useCallback(() => {
    if (!embla) return

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const engine = embla.internalEngine()
    const scrollProgress = embla.scrollProgress()

    const styles = embla.scrollSnapList().map((scrollSnap, index) => {
      if (references && references?.length <= 1) return 1
      if (!embla.slidesInView().includes(index)) return 0
      const diffToTarget = scrollSnap - scrollProgress
      const scale = 1 - Math.abs(diffToTarget * SCALE_FACTOR)
      return numberWithinRange(scale, 0, 1)
    })
    // @ts-ignore
    setScaleValues(styles)
  }, [embla, setScaleValues])

  useEffect(() => {
    if (!embla) return
    onSelect()
    onScroll()
    embla.on('select', onSelect)
    embla.on('scroll', onScroll)
    embla.on('resize', onScroll)
  }, [embla, onSelect, onScroll])

  if (references?.length) {
    return (
      <S.Slider>
        <S.NavContainerMobile>
          <S.Nav>
            <S.PrevButton onClick={scrollPrev} disabled={!prevBtnEnabled}>
              <S.ButtonIcon />
            </S.PrevButton>
            <S.NextButton onClick={scrollNext} disabled={!nextBtnEnabled}>
              <S.ButtonIcon />
            </S.NextButton>
          </S.Nav>
        </S.NavContainerMobile>
        <S.SliderViewport ref={viewportRef}>
          <S.SliderContainer>
            {references.map((reference, index) => (
              <S.SliderSlide key={index}>
                <S.SliderSlideInner scale={scaleValues[index]}>
                  {reference && <DeviceSliderItem {...reference} />}
                </S.SliderSlideInner>
              </S.SliderSlide>
            ))}
          </S.SliderContainer>
        </S.SliderViewport>
        {references.length > 1 && (
          <S.NavContainer>
            <S.Nav>
              <S.PrevButton onClick={scrollPrev} disabled={!prevBtnEnabled}>
                <S.ButtonIcon />
              </S.PrevButton>
              <S.NextButton onClick={scrollNext} disabled={!nextBtnEnabled}>
                <S.ButtonIcon />
              </S.NextButton>
            </S.Nav>
          </S.NavContainer>
        )}
      </S.Slider>
    )
  }
  return null
}
