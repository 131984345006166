import styled from '@emotion/styled'
import { H2 } from '@atoms/Title'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  background-color: #fff;
  padding-bottom: 10px;
  padding-top: 20px;

  ${mediaQuery.tablet.up} {
    padding-bottom: 20px;
  }
`

export const Title = styled(H2)`
  padding-top: 60px;
  margin-bottom: 40px;
`
