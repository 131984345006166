import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Line1 = styled.span<{ content: string }>`
  display: block;
  position: relative;
  text-align: center;

  &::before {
    content: '${(props) => props.content}';
    display: block;
    position: absolute;
    left: calc(50% + 40px);
    top: calc(50% - 20px);
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.025;
    z-index: -1;
  }

  ${mediaQuery.tabletXs.up} {
    text-align: left;

    &::before {
      left: 60px;
      top: -40px;
      transform: scale(1.2);
    }
  }

  ${mediaQuery.desktop.up} {
    &::before {
      top: -70px;
      left: -72px;
    }
  }
`

export const Line2 = styled.span<{ content: string }>`
  display: block;
  position: relative;
  text-align: center;

  &::before {
    content: '${(props) => props.content}';
    display: block;
    position: absolute;
    opacity: 0.025;
    z-index: -1;
    left: calc(50% - 30px);
    top: calc(50% + 10px);
    transform: translate(-50%, -50%) scale(1.2);
  }

  ${mediaQuery.tabletXs.up} {
    margin-left: 70px;
    text-align: left;
    &::before {
      top: -50px;
      left: 12px;
      transform: scale(1.4);
    }
  }

  @media screen and (min-width: 880px) {
    margin-left: 170px;

    &::before {
      top: -30px;
    }
  }

  ${mediaQuery.tablet.up} {
    margin-left: 240px;

    &::before {
      top: -30px;
    }
  }

  ${mediaQuery.desktop.up} {
    margin-left: 310px;

    &::before {
      top: -50px;
    }
  }
`

export const Line3 = styled.span<{ content: string }>`
  display: block;
  position: relative;
  text-align: center;

  &::before {
    content: '${(props) => props.content}';
    display: block;
    position: absolute;
    opacity: 0.025;
    z-index: -1;
    left: calc(50% + 40px);
    top: calc(50% + 20px);
    width: 100%;
    transform: translate(-50%, -50%) scale(1.2);
  }

  ${mediaQuery.tabletXs.up} {
    margin-left: 20px;
    text-align: left;

    &::before {
      left: -20px;
      transform: scale(1.4);
      top: 0;
    }
  }

  @media screen and (min-width: 880px) {
    margin-left: 60px;

    &::before {
      top: -30px;
    }
  }

  ${mediaQuery.desktop.up} {
    &::before {
      bottom: -20px;
      left: -52px;
      transform: scale(1.4);
    }
  }
`

export const Blob = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 180px;
  right: 100px;
  overflow: visible;
  z-index: -2;
  display: none;

  ${mediaQuery.tabletXs.up} {
    display: block;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const BlobMobile = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 180px;
  right: 100px;
  overflow: visible;
  z-index: -2;

  ${mediaQuery.tabletXs.up} {
    display: none;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 32px;
  margin-bottom: 70px;

  ${mediaQuery.tabletXs.up} {
    flex-direction: row;
    padding-top: 50px;
    margin-bottom: 140px;
  }

  ${mediaQuery.tablet.up} {
    padding-left: 60px;
    padding-right: 60px;
    margin: 0 auto 190px;
  }

  ${mediaQuery.desktop.up} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const LeftColumn = styled.div`
  width: 75%;
  position: relative;
  z-index: 5;
  margin-bottom: 10px;

  ${mediaQuery.tabletXs.up} {
    margin-bottom: 0;
  }

  ${mediaQuery.tablet.up} {
    width: 60%;
  }

  ${mediaQuery.desktop.up} {
  }
`

export const RightColumn = styled.div`
  padding-top: 60px;
  position: relative;
  width: 25%;
  min-width: 320px;

  ${mediaQuery.tablet.up} {
    width: 25%;
  }

  ${mediaQuery.desktop.up} {
  }
`
