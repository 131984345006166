import styled from '@emotion/styled'
import { H2 } from '@atoms/Title'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;

  ${mediaQuery.tablet.up} {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  ${mediaQuery.desktop.up} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`

export const Title = styled(H2)`
  margin-bottom: 32px;
`

export const InnerWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQuery.tabletXs.up} {
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
  }
`

export const TextWrap = styled.div`
  ${mediaQuery.tabletXs.up} {
    max-width: 400px;
  }

  ${mediaQuery.tablet.up} {
    max-width: 500px;
  }

  ${mediaQuery.desktop.up} {
    max-width: 550px;
  }
`

export const ImageWrap = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  order: -1;
  margin-bottom: 24px;

  ${mediaQuery.tabletXs.up} {
    margin-bottom: 0;
    width: 100%;
    order: 2;
    margin-top: -60px;
  }

  ${mediaQuery.tablet.up} {
    max-width: 600px;
  }
`
