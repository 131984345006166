import styled from '@emotion/styled'
import { H3 } from '@atoms/Title'
import Link from 'next/link'
import { mediaQuery } from '../../../themes/utils/breakpoints'
import { ArrowIcon } from '@molecules/TextGrid/TextGridItem.styled'

export const Title = styled(H3)`
  margin-bottom: 4px;

  ${mediaQuery.tablet.up} {
    margin-bottom: 8px;
  }
`

export const Excerpt = styled.div``

export const Icon = styled.div`
  width: 120px;
  filter: grayscale(100%) drop-shadow(-2px -2px 3px #ffffff) drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;

  ${mediaQuery.tablet.up} {
    margin-bottom: 40px;
  }
`

export const FakeButton = styled.div`
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  width: calc(100% - 32px);
  position: absolute;
  bottom: 24px;
  left: 16px;
  max-width: 300px;

  ${mediaQuery.tabletXs.up} {
    bottom: 40px;
    width: calc(100% - 110px);
    left: 55px;
  }
`

export const FakeButtonIcon = styled(ArrowIcon)`
  width: 120px;
  margin-left: 0;
  position: relative;
  top: 4px;
`

export const Wrap = styled(Link)`
  max-width: 340px;
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.body};
  box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 24px 16px 80px;
  position: relative;

  ${mediaQuery.tabletXs.up} {
    max-width: none;
    padding: 60px 55px 120px;
  }

  ${mediaQuery.tablet.up} {
    padding: 60px 55px 120px;
  }

  &:hover {
    ${Icon} {
      opacity: 1;
      filter: grayscale(0%) drop-shadow(-2px -2px 3px #ffffff) drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
    }
  }
`
