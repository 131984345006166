import styled from '@emotion/styled'
import { mediaQuery } from 'themes/utils/breakpoints'

export const Wrap = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  ${mediaQuery.tablet.up} {
    margin-bottom: 0;
  }
`

export const Number = styled.div`
  font-size: 64px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary};
`

export const Text = styled.div``