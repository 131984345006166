import { IComponentModulesPositionsGrid } from '@generated/index'
import { Container } from '@atoms/Container'
import * as S from './PositionsGrid.styled'
import { FC } from 'react'
import { TextGrid } from '@molecules/TextGrid'

export type PositionsGridProps = IComponentModulesPositionsGrid

export const PositionsGrid: FC<PositionsGridProps> = ({ title, text, positions }) => {
  return (
    <Container variant="white">
      <S.Content>
        <S.Intro>
          <S.TextWrap>
            <S.Title>{title}</S.Title>
            {text}
          </S.TextWrap>
          {/*
          {button && (
            <S.ButtonWrap>
              <ButtonIcon variant="primary" moduleBackground="grayLighter" button={button}>
                <span>{button?.title}</span>
              </ButtonIcon>
            </S.ButtonWrap>
          )}
           */}
        </S.Intro>
        {positions && <TextGrid positions={positions} />}
      </S.Content>
    </Container>
  )
}
