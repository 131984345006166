import styled from '@emotion/styled'
import { H2 } from '@atoms/Title'
import { FloatingInput as FloatingInputComponent } from '@atoms/FloatingInput'
import { FloatingTextarea as FloatingTextareaComponent } from '@atoms/FloatingTextarea'
import { mediaQuery } from '../../../themes/utils/breakpoints'
import { ErrorMessage } from '@atoms/FloatingInput/FloatingInput.styled'

export const Wrap = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
`

export const FormWrap = styled.div`
  box-shadow: -10px -10px 20px #fff, -30px -30px 30px ${({ theme }) => theme.colors.grayLighter},
    10px 0px 30px rgba(0, 0, 0, 0.2), 10px -10px 30px ${({ theme }) => theme.colors.grayLighter};
  padding: 32px 20px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.4);

  ${mediaQuery.tablet.up} {
    box-shadow: -20px -20px 40px rgba(255, 255, 255, 0.8), 20px 20px 40px #cfcfcf;
    max-width: 100%;
    padding: 40px;
    border-radius: 20px;
    background-color: transparent;
  }

  ${mediaQuery.desktop.up} {
    padding: 50px;
  }
`

export const Title = styled(H2)`
  margin-bottom: 40px;
  width: 100%;
  text-align: center;

  ${mediaQuery.tablet.up} {
    margin-bottom: 48px;
    text-align: left;
  }
`

export const InputRow = styled.div`
  display: flex;
  box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 0.8), inset 3px 3px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #f4f4f4;
  flex-direction: column;

  ${mediaQuery.tablet.up} {
    flex-direction: row;
    border-radius: 10px;
  }
`

export const FloatingInput = styled(FloatingInputComponent)<{ error: boolean }>`
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px solid #d9d9d9;
  }

  &:not(:first-of-type) {
    border-top: 1px solid #fff;
  }

  &:first-of-type {
    input {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }

  ${mediaQuery.tablet.up} {
    &:not(:last-of-type) {
      border-bottom: 0;
      border-right: 1px solid #d9d9d9;
    }

    &:not(:first-of-type) {
      border-top: 0;
      border-left: 1px solid #fff;
    }

    &:first-of-type {
      input {
        border-top-right-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: ${({ error }) => (error ? '0' : '10px')};
      }

      ${ErrorMessage} {
        border-bottom-left-radius: 10px;
      }
    }

    &:last-of-type {
      input {
        border-top-right-radius: 10px;
        border-bottom-right-radius: ${({ error }) => (error ? '0' : '10px')};
        border-bottom-left-radius: 0;
      }

      ${ErrorMessage} {
        border-bottom-right-radius: 10px;
      }
    }
  }
`

export const SecondRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 8px;

  ${mediaQuery.tablet.up} {
    margin-top: 36px;
    flex-direction: row;
    align-items: center;
  }
`

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery.tablet.up} {
    margin-left: 30px;
  }

  ${mediaQuery.desktop.up} {
    margin-left: 50px;
  }
`

export const FloatingTextarea = styled(FloatingTextareaComponent)`
  width: 100%;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  ${mediaQuery.tablet.up} {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`

export const Text = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
`

export const SendError = styled.div<{ show: boolean }>`
  margin-top: 12px;
  margin-bottom: 24px;
  color: #800000;
  font-weight: 800;
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export const VisibilityWrap = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`
