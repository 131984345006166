import React, { FC } from 'react'
import Image from 'next/image'
import * as S from './DeviceSliderItem.styled'
import { getStrapiRoot } from '@utils/env'
import { sizes } from '../../../themes/utils/breakpoints'
import { StrapiImageEntity } from '@strapi/strapiImage'
import { FormattedText } from '@atoms/FormattedText'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import { Reference, Tag } from 'models/types'

type DeviceSliderProps = Reference & {
  logo: StrapiImageEntity
  mockupImage: StrapiImageEntity
  mockupImageMobile: StrapiImageEntity
  tags: Tag[]
}

export const DeviceSliderItem: FC<DeviceSliderProps> = ({
  mockupImage,
  mockupImageMobile,
  logoSvg,
  name,
  logo,
  //slug,
  excerpt,
  tags,
}) => {
  let mockupImageUrl = null
  let mockupImageMobileUrl = null
  /*const button = {
    title: 'Detail Reference',
    url: slug,
    isExternal: false,
  }*/

  if (mockupImage) {
    mockupImageUrl = getStrapiRoot() + mockupImage.url
  }

  if (mockupImageMobile) {
    mockupImageMobileUrl = getStrapiRoot() + mockupImageMobile.url
  }

  const aspectRatioGoal = 0.2162162162
  const aspectRatio: number = (mockupImage?.width | 0) / (mockupImage?.height | 0)

  return (
    <S.Wrap>
      <ParallaxProvider>
        <S.DevicesWrap>
          <S.DesktopFrame>
            <S.Desktop bgUrl={logo?.url}>
              <Parallax
                disabled={aspectRatio >= aspectRatioGoal}
                speed={200}
                startScroll={600}
                rootMargin={{ top: 3000, left: 0, right: 0, bottom: 0 }}
              >
                {mockupImage && mockupImageUrl && (
                  <Image
                    src={mockupImageUrl}
                    alt={mockupImage.alternativeText ?? 'mockup image'}
                    width={mockupImage.width}
                    height={mockupImage.height}
                    placeholder={mockupImage.placeholder && mockupImage.placeholder !== null ? 'blur' : 'empty'}
                    quality={85}
                    blurDataURL={mockupImage.placeholder}
                    sizes={`${sizes.tablet} 0px, ${sizes.desktop} 672px`}
                  />
                )}
              </Parallax>
              {!mockupImage && (
                <S.EmptyImage
                  dangerouslySetInnerHTML={{
                    __html: logoSvg.code,
                  }}
                ></S.EmptyImage>
              )}
            </S.Desktop>
          </S.DesktopFrame>
          <S.MobileFrame>
            <S.Mobile>
              {mockupImageMobile && mockupImageMobileUrl && (
                <Parallax speed={20} startScroll={600} rootMargin={{ top: 1200, left: 0, right: 0, bottom: 0 }}>
                  <Image
                    src={mockupImageMobileUrl}
                    alt={mockupImageMobile.alternativeText ?? 'mobile mockup image'}
                    width={mockupImageMobile.width}
                    height={mockupImageMobile.height}
                    placeholder={
                      mockupImageMobile.placeholder && mockupImageMobile.placeholder !== null ? 'blur' : 'empty'
                    }
                    blurDataURL={mockupImageMobile.placeholder}
                    quality={98}
                    sizes={`${sizes.tablet} 290px, ${sizes.desktop} 180px`}
                  />
                </Parallax>
              )}
              {!mockupImage && (
                <S.EmptyImage
                  dangerouslySetInnerHTML={{
                    __html: logoSvg.code,
                  }}
                ></S.EmptyImage>
              )}
            </S.Mobile>
          </S.MobileFrame>
        </S.DevicesWrap>
        <S.CompanyWrap>
          <S.LogoWrap
            dangerouslySetInnerHTML={{
              __html: logoSvg.code,
            }}
          ></S.LogoWrap>
          <S.Title>{name}</S.Title>
          <S.CompanyText>
            <FormattedText dangerouslySetInnerHTML={{ __html: excerpt ?? '' }} />
          </S.CompanyText>
          <S.TagsWrap>
            {tags?.map((tag, index) => (
              <S.Tag key={index}>#{tag.title}</S.Tag>
            ))}
          </S.TagsWrap>
          {/*<ButtonIcon variant="primary" moduleBackground="grayLighter" button={button}>
          {button.title}
          </ButtonIcon>*/}
        </S.CompanyWrap>
      </ParallaxProvider>
    </S.Wrap>
  )
}
