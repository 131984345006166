import React, { FC } from 'react'
import * as S from './AboutUs.styled'
import { IComponentModulesAboutUs } from '@generated/index'
import { ButtonIcon } from '@molecules/ButtonIcon'
import { Testimonial } from '@molecules/Testimonial'
import { FloatingNumbers } from '@molecules/FloatingNumbers'

export type AboutUsProps = IComponentModulesAboutUs

export const AboutUs: FC<AboutUsProps> = ({ title, text, button, testimonial, floatingNumbers }) => {
  return (
    <S.Container>
      <S.Background>
        <S.BackgroundSVG />
      </S.Background>
      <S.Wrap>
        <S.TextWrap>
          <S.Title>{title}</S.Title>
          <S.Text>{text}</S.Text>
          {button && (
            <S.ButtonWrap>
              <ButtonIcon button={button} variant="primary" moduleBackground="grayDarker">
                {button?.title}
              </ButtonIcon>
            </S.ButtonWrap>
          )}
        </S.TextWrap>
        {testimonial && (
          <S.TestimonialWrap>
            <Testimonial {...testimonial} />
          </S.TestimonialWrap>
        )}
      </S.Wrap>
      {floatingNumbers?.numbers && (
        <S.NumbersWrap>
          <FloatingNumbers numbers={floatingNumbers.numbers} />
        </S.NumbersWrap>
      )}
    </S.Container>
  )
}
